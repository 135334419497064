import React from 'react';
import { motion } from 'framer-motion';
import { Cookie as CookieIcon, Settings, BarChart2, Wrench, BellRing } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags';

const CookiePolicy: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <MetaTags 
        title="Cookie Policy"
        description="Learn about our cookie policy and how we use cookies to enhance your experience."
        page="cookie"
      />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-4xl mx-auto px-4 py-12"
      >
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <CookieIcon className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl font-bold mb-4 text-gradient">Cookie Policy</h1>
          <p className="text-gray-400">Last Updated: {currentDate}</p>
        </div>

        <div className="space-y-12">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <CookieIcon className="w-6 h-6 text-violet-400" />
              1. What Are Cookies
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                Cookies are small text files that are placed on your device when you visit our website. They serve various purposes and help us provide you with a better, more personalized experience. These files contain information about your browsing preferences, device information, and how you interact with our website.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Wrench className="w-6 h-6 text-violet-400" />
              2. Types of Cookies We Use
            </h2>
            <div className="space-y-4">
              <div className="glass-effect p-6 rounded-xl">
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <Settings className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Essential Cookies</h3>
                    <p className="text-gray-300">
                      These cookies are necessary for the website to function properly. They enable basic functions like page navigation, access to secure areas, and proper operation of our service. The website cannot function properly without these cookies.
                    </p>
                  </div>
                </div>
              </div>

              <div className="glass-effect p-6 rounded-xl">
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <BarChart2 className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Analytics Cookies</h3>
                    <p className="text-gray-300">
                      These cookies help us understand how visitors interact with our website. They provide information about metrics such as number of visitors, bounce rate, traffic source, etc. This helps us improve our website's performance and user experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="glass-effect p-6 rounded-xl">
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <Settings className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Preference Cookies</h3>
                    <p className="text-gray-300">
                      These cookies remember your settings and choices, such as language preferences and color themes. They help provide a more personalized experience by maintaining your preferences across sessions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="glass-effect p-6 rounded-xl">
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <BellRing className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Marketing Cookies</h3>
                    <p className="text-gray-300">
                      These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Settings className="w-6 h-6 text-violet-400" />
              3. Managing Cookies
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 mb-4">
                You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
              </p>
              <div className="space-y-2">
                <p className="text-gray-300">You can manage your cookie preferences through your browser settings:</p>
                <ul className="list-disc list-inside text-gray-300 ml-4 space-y-1">
                  <li>Chrome: Settings → Privacy and Security → Cookies and other site data</li>
                  <li>Firefox: Options → Privacy & Security → Cookies and Site Data</li>
                  <li>Safari: Preferences → Privacy → Cookies and website data</li>
                  <li>Edge: Settings → Cookies and site permissions → Cookies and site data</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <div className="glass-effect p-6 rounded-xl bg-violet-500/5">
              <p className="text-gray-300">
                For more information about cookies and your privacy rights, you can visit our{' '}
                <a href="/privacy" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Privacy Policy
                </a>
                {' '}or contact us through our{' '}
                <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Contact Page
                </a>
                .
              </p>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default CookiePolicy;