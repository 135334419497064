import React from 'react';
import { motion } from 'framer-motion';
import { FileText, AlertCircle, Scale, Shield } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags';

const Terms: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <MetaTags 
        page="terms"
      />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-4xl mx-auto px-4 py-12"
      >
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <Scale className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl font-bold mb-4 text-gradient">Terms of Use</h1>
          <p className="text-gray-400">Last Updated: {currentDate}</p>
        </div>

        <div className="space-y-12">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <BookOpen className="w-6 h-6 text-violet-400" />
              1. Acceptance of Terms
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                By accessing or using BrandBolt.ai, you agree to be bound by these Terms of Use. If you do not agree to these terms, please do not use our service.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <FileText className="w-6 h-6 text-violet-400" />
              2. Service Description
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 mb-4">
                We provide an automated tool for generating brand name and domain name suggestions. While we strive for quality results, we do not guarantee:
              </p>
              <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                <li>Availability of suggested domain names</li>
                <li>Trademark clearance of suggested names</li>
                <li>Suitability for any specific purpose</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Shield className="w-6 h-6 text-violet-400" />
              3. User Responsibilities
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 mb-4">You agree to:</p>
              <ul className="text-gray-300 space-y-3">
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                  Provide accurate information when using the service
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                  Not use the service for any illegal purposes
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                  Not infringe on others' intellectual property rights
                </li>
                <li className="flex items-center gap-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                  Not attempt to reverse engineer the service
                </li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Gavel className="w-6 h-6 text-violet-400" />
              4. Intellectual Property
            </h2>
            <div className="space-y-6">
              <div className="glass-effect p-6 rounded-xl">
                <h3 className="text-lg font-semibold mb-3 text-white">4.1 Our Rights</h3>
                <ul className="text-gray-300 space-y-2">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    The service, including its algorithms and interface, is our intellectual property
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    Generated names are provided for your consideration only
                  </li>
                </ul>
              </div>

              <div className="glass-effect p-6 rounded-xl">
                <h3 className="text-lg font-semibold mb-3 text-white">4.2 Your Rights</h3>
                <ul className="text-gray-300 space-y-2">
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    You may use generated names subject to third-party rights
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                    You are responsible for checking trademark availability
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <AlertCircle className="w-6 h-6 text-violet-400" />
              5. Disclaimer of Warranties
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 font-semibold">
                THE SERVICE IS PROVIDED "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white">6. Limitation of Liability</h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to the use of our service.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white">7. Modifications</h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                We reserve the right to modify these terms at any time. Continued use of the service constitutes acceptance of modified terms. Users will be notified of significant changes.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white">8. Governing Law</h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                These terms shall be governed by and construed in accordance with the laws of the United States of America.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white">9. Contact Information</h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                For questions about these terms, please visit our{' '}
                <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Contact Page
                </a>
                .
              </p>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default Terms;