import React from 'react';
import { motion } from 'framer-motion';
import { Scissors, Sparkles, Target, Zap, CheckCircle, HelpCircle, Globe, Palette } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';
import JsonLd from '../../components/SEO/JsonLd';
import MetaTags from '../../components/SEO/MetaTags';

const FashionBrandNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Palette className="w-6 h-6" />,
      title: "Style-Focused",
      description: "Names that capture the essence of fashion and style"
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Global Appeal",
      description: "Fashion-forward names that work internationally"
    },
    {
      icon: <Scissors className="w-6 h-6" />,
      title: "Industry Specific",
      description: "Tailored for clothing, accessories, and lifestyle brands"
    }
  ];

  const examples = [
    {
      industry: "Luxury Fashion",
      names: ["EleganceElite", "LuxeVogue", "HauteCraft"],
      description: "Sophisticated names for high-end fashion"
    },
    {
      industry: "Streetwear",
      names: ["UrbanPulse", "StreetVibe", "RebelsRow"],
      description: "Edgy names for streetwear brands"
    },
    {
      industry: "Sustainable Fashion",
      names: ["EcoThread", "GreenStitch", "EarthChic"],
      description: "Eco-conscious fashion brand names"
    }
  ];

  const tips = [
    "Consider your fashion niche",
    "Make it internationally appealing",
    "Keep it sophisticated yet memorable",
    "Check trademark availability",
    "Think about logo potential",
    "Ensure social media handles are available"
  ];

  const faqs = [
    {
      question: "What makes a great fashion brand name?",
      answer: "A great fashion brand name should be memorable, evoke style and elegance, work well visually in logos and marketing materials, and resonate with your target audience. It should also be unique within the fashion industry."
    },
    {
      question: "Should I use my own name for my fashion brand?",
      answer: "Using your name can work well for designer fashion brands, but consider whether it's memorable, easy to pronounce, and has global appeal. Many successful fashion brands use creative names that reflect their style and vision."
    },
    {
      question: "How important is trademark availability?",
      answer: "Extremely important in the fashion industry. We recommend thorough trademark searches in all markets where you plan to sell, as fashion is a global industry with many protected brand names."
    }
  ];

  return (
    <>
      <MetaTags page="fashionbrandnamegenerator" />
      <JsonLd page="fashionbrandnamegenerator" url="https://brandbolt.ai/tools/fashion-brand-name-generator" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <Scissors className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            Fashion Brand Name Generator
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Create a stylish and memorable name for your fashion brand that captures your unique aesthetic
          </p>
        </motion.div>

        {/* Generator Tool */}
        <NameGenerator onGenerateNames={handleGenerateNames} />
        <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

        {/* How It Works */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Target className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">1. Define Your Style</h3>
              <p className="text-gray-300">Tell us about your fashion brand's aesthetic</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Sparkles className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
              <p className="text-gray-300">Get fashion-forward name suggestions</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <CheckCircle className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">3. Verify & Choose</h3>
              <p className="text-gray-300">Check availability and select your brand name</p>
            </motion.div>
          </div>
        </section>

        {/* Examples */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {examples.map((example, index) => (
              <motion.div
                key={index}
                className="glass-effect p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
                <ul className="space-y-2 mb-4">
                  {example.names.map((name, i) => (
                    <li key={i} className="text-gray-300">{name}</li>
                  ))}
                </ul>
                <p className="text-sm text-gray-400">{example.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Tips */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {tips.map((tip, index) => (
              <motion.div
                key={index}
                className="glass-effect p-4 rounded-xl flex items-center gap-4"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="p-2 bg-violet-500/10 rounded-lg">
                  <Zap className="w-5 h-5 text-violet-400" />
                </div>
                <p className="text-gray-300">{tip}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* FAQ */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                className="glass-effect p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <HelpCircle className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">{faq.question}</h3>
                    <p className="text-gray-300">{faq.answer}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default FashionBrandNameGenerator;