import React from 'react';
import { Link } from 'react-router-dom';
import { Zap, Twitter, Instagram } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 dark:bg-gray-950 text-white py-8 sm:py-12 transition-colors duration-200">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center mb-4">
              <Zap className="text-violet-400 mr-2" size={24} />
              <span className="text-xl sm:text-2xl font-bold">BrandBolt.ai</span>
            </div>
            <p className="text-gray-400 text-sm">Empowering businesses with AI-driven naming solutions</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-sm">
              <li><Link to="/" className="text-gray-400 hover:text-violet-400 transition-colors">Home</Link></li>
              <li><Link to="/about" className="text-gray-400 hover:text-violet-400 transition-colors">About</Link></li>
              <li><Link to="/features" className="text-gray-400 hover:text-violet-400 transition-colors">Features</Link></li>
              <li><Link to="/blog" className="text-gray-400 hover:text-violet-400 transition-colors">Blog</Link></li>
              <li><Link to="/pricing" className="text-gray-400 hover:text-violet-400 transition-colors">Pricing</Link></li>
              <li><Link to="/contact" className="text-gray-400 hover:text-violet-400 transition-colors">Contact</Link></li>
              <li><Link to="/faq" className="text-gray-400 hover:text-violet-400 transition-colors">FAQ</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2 text-sm">
              <li><Link to="/privacy" className="text-gray-400 hover:text-violet-400 transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms" className="text-gray-400 hover:text-violet-400 transition-colors">Terms of Service</Link></li>
              <li><Link to="/cookie" className="text-gray-400 hover:text-violet-400 transition-colors">Cookie Policy</Link></li>
              <li><Link to="/gdpr" className="text-gray-400 hover:text-violet-400 transition-colors">GDPR Compliance</Link></li>
              <li><Link to="/acceptable-use" className="text-gray-400 hover:text-violet-400 transition-colors">Acceptable Use</Link></li>
              <li><Link to="/disclaimer" className="text-gray-400 hover:text-violet-400 transition-colors">Disclaimer</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Connect</h4>
            <div className="flex space-x-4">
              <a href="https://twitter.com/BrandBoltai" className="text-gray-400 hover:text-violet-400 transition-colors" target="_blank" rel="noopener noreferrer">
                <Twitter size={24} />
              </a>
              <a href="https://www.instagram.com/brandbolt_ai/" className="text-gray-400 hover:text-violet-400 transition-colors" target="_blank" rel="noopener noreferrer">
                <Instagram size={24} />
              </a>
              <a href="https://pinterest.com/Brandbolt_ai" className="text-gray-400 hover:text-violet-400 transition-colors" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"/>
                  <path d="M12 6v12m-3-3 3 3 3-3"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400 text-sm">
          &copy; {new Date().getFullYear()} BrandBolt.ai. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;