import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Sparkles, Target, CheckCircle, Globe, Zap, HelpCircle } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';
import JsonLd from '../../components/SEO/JsonLd';
import MetaTags from '../../components/SEO/MetaTags';

const OnlineStoreNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <ShoppingBag className="w-6 h-6" />,
      title: "E-commerce Focus",
      description: "Names perfect for online retail and e-commerce businesses"
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Digital-First",
      description: "Modern names that work great for online presence"
    },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      title: "Domain Friendly",
      description: "Names optimized for domain availability"
    }
  ];

  const examples = [
    {
      industry: "Fashion & Apparel",
      names: ["StyleVault", "TrendTrove", "ChicClick"],
      description: "Trendy names for fashion e-commerce"
    },
    {
      industry: "Electronics",
      names: ["TechCart", "GadgetGrid", "ByteBazaar"],
      description: "Tech-savvy online store names"
    },
    {
      industry: "Home & Living",
      names: ["NestNook", "HomeHaven", "DwellDirect"],
      description: "Cozy names for home goods stores"
    }
  ];

  const tips = [
    "Make it easy to spell and type",
    "Consider your product niche",
    "Check domain availability",
    "Keep it memorable",
    "Think about SEO potential",
    "Ensure social media handles are free"
  ];

  const faqs = [
    {
      question: "What makes a good online store name?",
      answer: "A good online store name should be memorable, easy to type, relevant to your products, and have available domain names. It should also work well for branding and social media."
    },
    {
      question: "Should I include keywords in my store name?",
      answer: "While keywords can help with SEO, they shouldn't compromise the brand's uniqueness. Balance searchability with creativity to create a distinctive brand identity."
    },
    {
      question: "How important is the domain name?",
      answer: "Very important for online stores. Your domain should match your store name exactly if possible, and preferably be available as a .com to build trust with customers."
    }
  ];

  return (
    <>
      <MetaTags page="onlinestorenamegenerator" />
      <JsonLd page="onlinestorenamegenerator" url="https://brandbolt.ai/tools/online-store-name-generator" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <ShoppingBag className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            Online Store Name Generator
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Create a catchy, memorable name for your e-commerce business that customers will love
          </p>
        </motion.div>

        {/* Generator Tool */}
        <NameGenerator onGenerateNames={handleGenerateNames} />
        <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

        {/* How It Works */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Target className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">1. Describe Your Store</h3>
              <p className="text-gray-300">Tell us about your products and target market</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Sparkles className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
              <p className="text-gray-300">Our AI creates catchy, e-commerce friendly names</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <CheckCircle className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">3. Pick Your Favorite</h3>
              <p className="text-gray-300">Choose and save your perfect store name</p>
            </motion.div>
          </div>
        </section>

        {/* Features */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl text-center"
                whileHover={{ scale: 1.02 }}
              >
                <div className="text-violet-400 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-white">{feature.title}</h3>
                <p className="mt-2 text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Examples */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {examples.map((example, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl"
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
                <ul className="space-y-2">
                  {example.names.map((name, nameIndex) => (
                    <li key={nameIndex} className="text-gray-300">{name}</li>
                  ))}
                </ul>
                <p className="mt-4 text-gray-400">{example.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Tips */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {tips.map((tip, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-4 rounded-xl flex items-center"
              >
                <div className="text-violet-400 mr-4">
                  <CheckCircle className="w-6 h-6" />
                </div>
                <p className="text-gray-300">{tip}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* FAQs */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl"
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{faq.question}</h3>
                <p className="text-gray-300">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default OnlineStoreNameGenerator;