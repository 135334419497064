import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheck, UserCheck, Database, FileCheck, Lock } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags';

const GDPR: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <MetaTags 
        page="gdpr"
      />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-4xl mx-auto px-4 py-12"
      >
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <ShieldCheck className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl font-bold mb-4 text-gradient">GDPR Compliance Statement</h1>
          <p className="text-gray-400">Last Updated: {currentDate}</p>
        </div>

        <div className="space-y-12">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Database className="w-6 h-6 text-violet-400" />
              1. Data Controller
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300">
                BrandBolt.ai is the data controller for personal data collected through our service. We are committed to protecting your privacy and ensuring transparent data processing practices.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <UserCheck className="w-6 h-6 text-violet-400" />
              2. Your Rights
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 mb-4">Under GDPR, you have the following rights:</p>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-3">
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">Access your personal data and receive a copy of it</p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">Request correction of inaccurate data</p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">Request deletion of your personal data</p>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">Export your data in a portable format</p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">Withdraw consent at any time</p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-violet-400 mt-2"></div>
                    <p className="text-gray-300">File complaints with supervisory authorities</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <FileCheck className="w-6 h-6 text-violet-400" />
              3. Data Processing
            </h2>
            <div className="glass-effect p-6 rounded-xl">
              <p className="text-gray-300 mb-4">We process your data based on the following legal grounds:</p>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <Lock className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Consent</h3>
                    <p className="text-gray-300">
                      When you explicitly agree to the processing of your personal data for specific purposes, such as receiving marketing communications or analyzing your service usage patterns.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <FileCheck className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Contractual Necessity</h3>
                    <p className="text-gray-300">
                      When processing is necessary for the performance of a contract with you or to take steps at your request before entering into a contract.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <ShieldCheck className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">Legitimate Interests</h3>
                    <p className="text-gray-300">
                      When processing is necessary for our legitimate interests or those of a third party, except where such interests are overridden by your interests or fundamental rights and freedoms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="glass-effect p-6 rounded-xl bg-violet-500/5">
              <p className="text-gray-300">
                To exercise any of these rights or learn more about our data processing practices, please contact our Data Protection Officer through our{' '}
                <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Contact Page
                </a>
                . For more information about how we handle your data, please review our{' '}
                <a href="/privacy" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default GDPR;