import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HelpCircle, Plus, Minus, Zap, Globe, CreditCard, Search, Save, Lock, RefreshCw, Sparkles } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags'; // Assuming MetaTags is in this location
import JsonLd from '../components/SEO/JsonLd'; // Assuming JsonLd is in this location

interface FAQItemProps {
  question: string;
  answer: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, isOpen, onToggle }) => {
  return (
    <div className="glass-effect rounded-xl overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between text-left transition-colors hover:bg-violet-500/5"
      >
        <span className="font-semibold text-white">{question}</span>
        {isOpen ? (
          <Minus className="w-5 h-5 text-violet-400" />
        ) : (
          <Plus className="w-5 h-5 text-violet-400" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="px-6 py-4 border-t border-gray-800 text-gray-300">
              {answer}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ: React.FC = () => {
  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

  const toggleItem = (id: string) => {
    setOpenItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const generalQuestions = [
    {
      id: 'how-it-works',
      question: 'How does the name generator work?',
      answer: (
        <div className="space-y-4">
          <p>
            Our AI-powered name generator uses advanced algorithms to create unique and relevant business names based on your input. The process involves:
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Analyzing your business description and industry</li>
            <li>Considering your specified preferences and criteria</li>
            <li>Generating names using machine learning models</li>
            <li>Checking domain availability in real-time</li>
          </ul>
        </div>
      )
    },
    {
      id: 'trademark',
      question: 'Are generated names trademarked?',
      answer: (
        <div className="space-y-4">
          <p>
            No, generated names are not automatically trademarked. While our system tries to avoid known trademarks, you should:
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Conduct a proper trademark search</li>
            <li>Consult with a legal professional</li>
            <li>Register your trademark if desired</li>
          </ul>
        </div>
      )
    },
    {
      id: 'name-quality',
      question: 'How do you ensure name quality?',
      answer: (
        <p>
          Each generated name goes through multiple quality checks, including relevance scoring, pronunciation analysis, and similarity detection. We also provide a relevance score and explanation for each name to help you make informed decisions.
        </p>
      )
    }
  ];

  const technicalQuestions = [
    {
      id: 'save-names',
      question: 'Can I save my favorite names?',
      answer: (
        <div className="space-y-4">
          <p>Yes! You can save names in different ways:</p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Free users can save up to 10 names</li>
            <li>Pro users get unlimited name saves</li>
            <li>All saved names include their domain availability status</li>
            <li>Access your saved names anytime from your dashboard</li>
          </ul>
        </div>
      )
    },
    {
      id: 'domain-check',
      question: 'How do I check domain availability?',
      answer: (
        <p>
          Domain availability is checked automatically for each generated name. Free users can check .com domains, while premium users can check multiple TLDs including .io, .ai, .app, and more. Results are displayed in real-time with the option to refresh the check.
        </p>
      )
    },
    {
      id: 'api-access',
      question: 'Do you offer API access?',
      answer: (
        <p>
          Yes, API access is available for Enterprise plan subscribers. This includes programmatic access to our name generation engine, domain availability checking, and analytics. Contact us for custom API solutions and documentation.
        </p>
      )
    }
  ];

  const billingQuestions = [
    {
      id: 'payment-methods',
      question: 'What payment methods do you accept?',
      answer: (
        <div className="space-y-4">
          <p>We accept various payment methods including:</p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>All major credit cards (Visa, MasterCard, American Express)</li>
            <li>PayPal</li>
            <li>Bank transfers (Enterprise plans only)</li>
            <li>Cryptocurrency (Bitcoin, Ethereum)</li>
          </ul>
        </div>
      )
    },
    {
      id: 'refunds',
      question: 'How do refunds work?',
      answer: (
        <p>
          We offer a 14-day money-back guarantee for all paid plans. If you're not satisfied with our service, contact our support team within 14 days of your purchase for a full refund. Enterprise plans have custom refund policies based on the contract terms.
        </p>
      )
    },
    {
      id: 'plan-changes',
      question: 'Can I change my plan later?',
      answer: (
        <p>
          Yes, you can upgrade, downgrade, or cancel your plan at any time. Upgrades take effect immediately, while downgrades and cancellations take effect at the end of your current billing cycle. Any unused portion of paid services is not refundable.
        </p>
      )
    }
  ];

  return (
    <>
      <MetaTags page="faq" />
      <JsonLd page="faq" url="https://brandbolt.ai/faq" />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-4xl mx-auto px-4 py-12"
      >
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <HelpCircle className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl font-bold mb-4 text-gradient">Frequently Asked Questions</h1>
          <p className="text-gray-400">Find answers to common questions about BrandBolt.ai</p>
        </div>

        <div className="space-y-12">
          <section>
            <div className="flex items-center gap-2 mb-6">
              <Sparkles className="w-6 h-6 text-violet-400" />
              <h2 className="text-2xl font-semibold text-white">General Questions</h2>
            </div>
            <div className="space-y-4">
              {generalQuestions.map(item => (
                <FAQItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  isOpen={openItems[item.id]}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </section>

          <section>
            <div className="flex items-center gap-2 mb-6">
              <Globe className="w-6 h-6 text-violet-400" />
              <h2 className="text-2xl font-semibold text-white">Technical Questions</h2>
            </div>
            <div className="space-y-4">
              {technicalQuestions.map(item => (
                <FAQItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  isOpen={openItems[item.id]}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </section>

          <section>
            <div className="flex items-center gap-2 mb-6">
              <CreditCard className="w-6 h-6 text-violet-400" />
              <h2 className="text-2xl font-semibold text-white">Billing Questions</h2>
            </div>
            <div className="space-y-4">
              {billingQuestions.map(item => (
                <FAQItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  isOpen={openItems[item.id]}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </section>

          <section>
            <div className="glass-effect p-6 rounded-xl bg-violet-500/5">
              <p className="text-gray-300">
                Can't find what you're looking for? Visit our{' '}
                <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Contact Page
                </a>
                {' '}for personalized support.
              </p>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default FAQ;