import axios from 'axios';
import api from './api';
import env from '../config/env';

interface DomainCheckResult {
  isAvailable: boolean;
  domain: string;
  error?: string;
  registrarUrl?: string;
  isPremium?: boolean;
  premiumPrice?: number;
}

export const getDomainRegistrarUrl = (domain: string): string => {
  // Remove http:// or https:// if present
  const cleanDomain = domain.replace(/^https?:\/\//, '');
  return `https://www.kqzyfj.com/click-101331290-15733823?url=${encodeURIComponent(`https://www.namecheap.com/domains/registration/results/?domain=${cleanDomain}`)}`;
};

export const checkDomainsAvailability = async (
  domains: string[]
): Promise<Record<string, DomainCheckResult>> => {
  try {
    const response = await api.post('/check-domain', { domains });
    const results = response.data;

    // Add registrar URLs to each result
    Object.keys(results).forEach(domain => {
      results[domain].registrarUrl = getDomainRegistrarUrl(domain);
    });

    return results;
  } catch (error) {
    console.error('Error checking domains availability:', error);
    return domains.reduce((acc, domain) => {
      acc[domain] = {
        domain,
        isAvailable: false,
        error: 'Failed to check domain availability',
        registrarUrl: getDomainRegistrarUrl(domain)
      };
      return acc;
    }, {} as Record<string, DomainCheckResult>);
  }
};