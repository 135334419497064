import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, Globe, Scale, BookOpen, AlertCircle } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags';

const Disclaimer: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <MetaTags page="disclaimer" />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-4xl mx-auto px-4 py-12"
      >
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <AlertTriangle className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl font-bold mb-4 text-gradient">Disclaimer</h1>
          <p className="text-gray-400">Last Updated: {currentDate}</p>
        </div>

        <div className="space-y-12">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <BookOpen className="w-6 h-6 text-violet-400" />
              1. Name Generation
            </h2>
            <div className="glass-effect p-6 rounded-xl space-y-6">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <AlertCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div className="space-y-4">
                  <p className="text-gray-300">
                    All names generated by BrandBolt.ai are provided as suggestions only. While we strive to provide high-quality, unique name suggestions, users should note:
                  </p>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Generated names are suggestions and not guaranteed to be unique or available
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      No guarantee of trademark or domain availability is provided
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Users must perform their own due diligence before using any suggested names
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Globe className="w-6 h-6 text-violet-400" />
              2. Domain Availability
            </h2>
            <div className="glass-effect p-6 rounded-xl space-y-6">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <AlertCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div className="space-y-4">
                  <p className="text-gray-300">
                    Our domain availability checking service is provided as a preliminary tool only:
                  </p>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Domain availability checks are preliminary and may not reflect real-time status
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Final availability must be verified with domain registrars
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Domain prices shown are estimates and may vary by registrar
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Registration status can change at any time
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
              <Scale className="w-6 h-6 text-violet-400" />
              3. Professional Advice
            </h2>
            <div className="glass-effect p-6 rounded-xl space-y-6">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                  <AlertCircle className="w-5 h-5 text-violet-400" />
                </div>
                <div className="space-y-4">
                  <p className="text-gray-300">
                    BrandBolt.ai is a name generation and domain checking tool only:
                  </p>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Our service does not provide legal advice or counsel
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Consult qualified professionals for trademark matters
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      Seek legal counsel for business naming decisions
                    </li>
                    <li className="flex items-center gap-2">
                      <div className="w-1.5 h-1.5 rounded-full bg-violet-400"></div>
                      We are not responsible for any decisions made based on our suggestions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="glass-effect p-6 rounded-xl bg-violet-500/5">
              <p className="text-gray-300">
                By using BrandBolt.ai, you acknowledge and agree to this disclaimer. For more information, please review our{' '}
                <a href="/terms" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Terms of Service
                </a>
                {' '}and{' '}
                <a href="/privacy" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Privacy Policy
                </a>
                . If you have any questions, please contact us through our{' '}
                <a href="/contact" className="text-violet-400 hover:text-violet-300 transition-colors">
                  Contact Page
                </a>
                .
              </p>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  );
};

export default Disclaimer;