import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Store, Briefcase, Globe, Wand2, Building2, Rocket } from 'lucide-react';
import MetaTags from '../components/SEO/MetaTags';
import JsonLd from '../components/SEO/JsonLd';

const tools = [
  {
    id: 'business-name-generator',
    title: 'Business Name Generator',
    description: 'Generate creative and memorable business names tailored to your industry',
    icon: <Briefcase className="w-6 h-6 text-violet-400" />,
    keywords: ['business', 'company', 'startup', 'enterprise']
  },
  {
    id: 'agency-name-generator',
    title: 'Agency Name Generator',
    description: 'Create professional and impactful names for your creative, marketing, or consulting agency',
    icon: <Building2 className="w-6 h-6 text-violet-400" />,
    keywords: ['agency', 'creative', 'marketing', 'consulting']
  },
  {
    id: 'saas-name-generator',
    title: 'SaaS Name Generator',
    description: 'Generate modern and catchy names for your software-as-a-service business',
    icon: <Globe className="w-6 h-6 text-violet-400" />,
    keywords: ['saas', 'software', 'cloud', 'tech']
  },
  {
    id: 'online-store-name-generator',
    title: 'Online Store Name Generator',
    description: 'Create catchy and memorable names for your e-commerce business',
    icon: <Store className="w-6 h-6 text-violet-400" />,
    keywords: ['ecommerce', 'shop', 'retail', 'store']
  },
  {
    id: 'fashion-brand-name-generator',
    title: 'Fashion Brand Name Generator',
    description: 'Design stylish and trendy names for your fashion brand or clothing line',
    icon: <Wand2 className="w-6 h-6 text-violet-400" />,
    keywords: ['fashion', 'clothing', 'apparel', 'style']
  },
  {
    id: 'domain-name-generator',
    title: 'Domain Name Generator',
    description: 'Find available and brandable domain names for your online presence',
    icon: <Globe className="w-6 h-6 text-violet-400" />,
    keywords: ['domain', 'website', 'URL', 'web address']
  },
  {
    id: 'startup-name-generator',
    title: 'Startup Name Generator',
    description: 'Create innovative and disruptive names for your tech startup',
    icon: <Rocket className="w-6 h-6 text-violet-400" />,
    keywords: ['startup', 'tech', 'innovation', 'digital']
  }
];

const Tools: React.FC = () => {
  return (
    <>
      <MetaTags page="tools" />
      <JsonLd page="tools" url="https://brandbolt.ai/tools" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <Store className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            AI-Powered Naming Tools
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Discover our suite of intelligent naming tools designed to help you create the perfect brand identity
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-6">
          {tools.map((tool, index) => (
            <motion.div
              key={tool.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Link to={`/tools/${tool.id}`}>
                <div className="glass-effect p-6 rounded-xl border border-gray-800 hover:border-violet-500 transition-all duration-200 h-full">
                  <div className="p-2 bg-violet-500/10 rounded-lg w-fit mb-4">
                    {tool.icon}
                  </div>
                  <h2 className="text-2xl font-bold mb-2 text-white">{tool.title}</h2>
                  <p className="text-gray-300 mb-4">{tool.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {tool.keywords.map((keyword, i) => (
                      <span
                        key={i}
                        className="px-3 py-1 bg-violet-500/10 rounded-full text-violet-300 text-sm"
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-12 text-center"
        >
          <h2 className="text-2xl font-bold mb-4 text-white">Need a Custom Solution?</h2>
          <p className="text-gray-300 mb-6">
            Our enterprise plans offer customized naming tools for your specific needs
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
          >
            Contact Us
          </Link>
        </motion.div>
      </div>
    </>
  );
};

export default Tools;