import React from 'react';
import { motion } from 'framer-motion';
import { Rocket, Sparkles, Target, Zap, CheckCircle, HelpCircle, Globe, Lightbulb } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';
import JsonLd from '../../components/SEO/JsonLd';
import MetaTags from '../../components/SEO/MetaTags';

const StartupNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Rocket className="w-6 h-6" />,
      title: "InnovationFocused",
      description: "Names that reflect innovation and disruption"
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "GlobalAppeal",
      description: "Names that work internationally"
    },
    {
      icon: <Lightbulb className="w-6 h-6" />,
      title: "FutureReady",
      description: "Names that scale with your vision"
    }
  ];

  const examples = [
    {
      industry: "TechStartups",
      names: ["ByteForge", "QuantumLeap", "NeuraTech"],
      description: "Modern names for innovative tech companies"
    },
    {
      industry: "FinTech",
      names: ["PayPulse", "CryptoFlow", "FinanceAI"],
      description: "Names for financial technology startups"
    },
    {
      industry: "AI/ML",
      names: ["CoreAI", "DeepMind", "NeuralNet"],
      description: "Names for artificial intelligence startups"
    }
  ];

  const tips = [
    "Keep it memorable and unique",
    "Consider future pivots",
    "Check domain availability",
    "Make it easy to pronounce",
    "Think globally",
    "Avoid limiting geography"
  ];

  const faqs = [
    {
      question: "What makes a great startup name?",
      answer: "A great startup name should be memorable, reflect your vision, be easy to pronounce and spell, have available domains, and scale with your business as it grows."
    },
    {
      question: "Should I use a descriptive or abstract name?",
      answer: "Both approaches can work. Descriptive names help explain your business but might limit future pivots. Abstract names offer more flexibility and can build stronger brand recognition."
    },
    {
      question: "How important is domain availability?",
      answer: "Very important. While alternative TLDs exist, having the .com domain remains valuable for credibility and brand protection. Consider securing key social media handles too."
    }
  ];

  return (
    <>
      <MetaTags page="startupnamegenerator" />
      <JsonLd page="startupnamegenerator" url="https://brandbolt.ai/tools/startup-name-generator" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <Rocket className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            Startup Name Generator
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Create a powerful, innovative name for your startup that investors and customers will love
          </p>
        </motion.div>

        {/* Generator Tool */}
        <NameGenerator onGenerateNames={handleGenerateNames} />
        <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

        {/* How It Works */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Target className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">1. Describe Your Vision</h3>
              <p className="text-gray-300">Tell us about your startup idea</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Sparkles className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
              <p className="text-gray-300">Get innovative startup name ideas</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <CheckCircle className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">3. Validate & Choose</h3>
              <p className="text-gray-300">Check availability and pick your name</p>
            </motion.div>
          </div>
        </section>

        {/* Examples */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {examples.map((example, index) => (
              <motion.div
                key={index}
                className="glass-effect p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
                <ul className="space-y-2 mb-4">
                  {example.names.map((name, i) => (
                    <li key={i} className="text-gray-300">{name}</li>
                  ))}
                </ul>
                <p className="text-sm text-gray-400">{example.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Tips */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {tips.map((tip, index) => (
              <motion.div
                key={index}
                className="glass-effect p-4 rounded-xl flex items-center gap-4"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="p-2 bg-violet-500/10 rounded-lg">
                  <Zap className="w-5 h-5 text-violet-400" />
                </div>
                <p className="text-gray-300">{tip}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* FAQ */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                className="glass-effect p-6 rounded-xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-start gap-4">
                  <div className="p-2 bg-violet-500/10 rounded-lg mt-1">
                    <HelpCircle className="w-5 h-5 text-violet-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-white">{faq.question}</h3>
                    <p className="text-gray-300">{faq.answer}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default StartupNameGenerator;