import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
  page: string;
  title?: string;
  description?: string;
  keywords?: string | string[];
  noindex?: boolean;
}

const normalizePageKey = (page: string): string => {
  return page.toLowerCase().replace(/[^a-z0-9]/g, '');
};

const getMetaTitle = (page: string, customTitle?: string): string => {
  if (customTitle) return `${customTitle} | BrandBolt`;

  const pageKey = normalizePageKey(page);
  const titles: { [key: string]: string } = {
    // Main pages
    'home': "BrandBolt | AI Business Name Generator | Instant Brand Creation",
    'about': "About BrandBolt | AI-Powered Brand Name Generator",
    'pricing': "BrandBolt Pricing | AI Name Generator Plans",
    'features': "BrandBolt Features | Advanced AI Brand Name Creation Tools",
    'tools': "BrandBolt Tools | Complete Brand Naming Suite",
    'contact': "Contact BrandBolt | Get Support & Information",
    'faq': "BrandBolt FAQ | Help Center & Support",
    'savednames': "Your Saved Brand Names | BrandBolt Dashboard",
    'success': "Thank You | BrandBolt",
    
    // Legal pages
    'privacy': "Privacy Policy | BrandBolt",
    'terms': "Terms of Service | BrandBolt",
    'gdpr': "GDPR Compliance | BrandBolt",
    'cookie': "Cookie Policy | BrandBolt",
    'disclaimer': "Legal Disclaimer | BrandBolt",
    'acceptableuse': "Acceptable Use Policy | BrandBolt",
    
    // Specialized tools
    'businessnamegenerator': "Business Name Generator | AI-Powered Brand Names | BrandBolt",
    'startupnamegenerator': "Startup Name Generator | Innovative Company Names | BrandBolt",
    'agencynamegenerator': "Agency Name Generator | Creative Agency Names | BrandBolt",
    'saasnamegenerator': "SaaS Name Generator | Software Company Names | BrandBolt",
    'fashionbrandnamegenerator': "Fashion Brand Name Generator | Stylish Names | BrandBolt",
    'onlinestorenamegenerator': "eCommerce Store Name Generator | Shop Names | BrandBolt",
    'domainnamegenerator': "Domain Name Generator | Available URLs | BrandBolt"
  };

  return titles[pageKey] || 'BrandBolt | AI Name Generator';
};

const getMetaDescription = (page: string, customDescription?: string): string => {
  if (customDescription) return customDescription;

  const pageKey = normalizePageKey(page);
  const descriptions: { [key: string]: string } = {
    // Main pages
    'home': "Discover BrandBolt's AI-powered business name generator. Create unique, memorable brand names instantly with domain availability checks.",
    'about': "Learn about BrandBolt's mission to transform brand naming with advanced AI technology and real-time domain checking. Your perfect brand name starts here.",
    'pricing': "Explore BrandBolt's flexible pricing plans. Find the perfect package for your brand naming needs.",
    'features': "Discover BrandBolt's powerful AI naming tools. Generate unique business names, check domain availability, and get trademark insights all in one place.",
    'tools': "Access our complete suite of AI-powered naming tools. Generate names for your business, startup, agency, or online store with instant availability checks.",
    'contact': "Get in touch with the BrandBolt team. We're here to help you create the perfect name for your business.",
    'faq': "Find answers to common questions about BrandBolt's AI name generator, features, pricing, and more.",
    'savednames': "Access your saved business names and brand ideas. Compare options and find the perfect name for your business.",
    'success': "Thank you for choosing BrandBolt. Start generating unique business names with our AI-powered tools.",
    
    // Legal pages
    'privacy': "Learn about how BrandBolt protects your privacy and handles your data. Read our comprehensive privacy policy.",
    'terms': "Read BrandBolt's terms of service. Understand your rights and responsibilities when using our AI name generator.",
    'gdpr': "Learn about BrandBolt's GDPR compliance and how we protect EU citizens' data rights.",
    'cookie': "Understand how BrandBolt uses cookies to improve your experience. Read our cookie policy.",
    'disclaimer': "Read BrandBolt's legal disclaimer regarding the use of our AI name generator and brand tools.",
    'acceptableuse': "Learn about acceptable use of BrandBolt's services and tools. Read our usage policy.",
    
    // Specialized tools
    'businessnamegenerator': "Generate unique business names instantly with our AI-powered tool. Get instant domain availability and trademark insights.",
    'startupnamegenerator': "Create innovative startup names with our AI generator. Find available domains and check trademark status.",
    'agencynamegenerator': "Generate creative agency names that stand out. Get instant domain availability and trademark insights.",
    'saasnamegenerator': "Create unique SaaS company names with our AI generator. Find available domains for your software business.",
    'fashionbrandnamegenerator': "Generate stylish fashion brand names instantly. Find available domains for your fashion business.",
    'onlinestorenamegenerator': "Create catchy eCommerce store names with our AI generator. Find available domains for your online shop.",
    'domainnamegenerator': "Find available domain names for your business. Generate unique URLs that match your brand."
  };

  return descriptions[pageKey] || "Generate unique business names instantly with BrandBolt's AI-powered name generator. Get domain availability and trademark insights.";
};

const getMetaKeywords = (page: string, customKeywords?: string | string[]): string => {
  if (customKeywords) {
    return Array.isArray(customKeywords) ? customKeywords.join(', ') : customKeywords;
  }

  const pageKey = normalizePageKey(page);
  const keywords: { [key: string]: string[] } = {
    'home': ['ai name generator', 'business name generator', 'brand name generator', 'company name generator', 'domain name checker'],
    'pricing': ['brandbolt pricing', 'name generator plans', 'ai naming tools', 'business name generator pricing', 'brand name generator cost'],
    'features': ['ai brand name generator', 'business name generator features', 'domain name checker', 'trademark search', 'brand identity tools'],
    'tools': ['business name generator tools', 'brand name generator', 'company name generator', 'startup name generator', 'domain name checker'],
    'businessnamegenerator': ['business name generator', 'company name ideas', 'brand name creator', 'business name suggestions', 'available business names'],
    'startupnamegenerator': ['startup name generator', 'company name ideas', 'tech startup names', 'innovative business names', 'available startup names'],
    'agencynamegenerator': ['agency name generator', 'creative agency names', 'marketing agency names', 'design agency names', 'digital agency names'],
    'saasnamegenerator': ['saas name generator', 'software company names', 'tech company names', 'app name generator', 'product name generator'],
    'fashionbrandnamegenerator': ['fashion brand names', 'clothing brand generator', 'apparel brand names', 'fashion label names', 'boutique name generator'],
    'onlinestorenamegenerator': ['online store names', 'ecommerce business names', 'shop name generator', 'store name ideas', 'retail business names'],
    'domainnamegenerator': ['domain name generator', 'website name ideas', 'available domain names', 'url generator', 'web address finder']
  };

  return keywords[pageKey]?.join(', ') || '';
};

const MetaTags: React.FC<MetaTagsProps> = ({ page, title, description, keywords, noindex }) => {
  const finalTitle = getMetaTitle(page, title);
  const finalDescription = getMetaDescription(page, description);
  const finalKeywords = getMetaKeywords(page, keywords);
  const canonicalUrl = `https://brandbolt.ai/${page === 'home' ? '' : page}`;

  return (
    <Helmet defer={false}>
      <html lang="en" />
      <title>{finalTitle}</title>
      <meta name="title" content={finalTitle} />
      <meta name="description" content={finalDescription} />
      <meta name="keywords" content={finalKeywords} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Technical Meta Tags */}
      <meta name="robots" content={noindex ? "noindex, nofollow" : "index, follow"} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Additional SEO Tags */}
      <meta name="author" content="BrandBolt" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="theme-color" content="#000000" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content="BrandBolt" />
      <meta name="application-name" content="BrandBolt" />

      {/* Language Alternates */}
      <link rel="alternate" href={`https://brandbolt.ai${page === 'home' ? '' : '/' + page}`} hrefLang="x-default" />
      <link rel="alternate" href={`https://brandbolt.ai${page === 'home' ? '' : '/' + page}`} hrefLang="en" />

      {/* OpenGraph Meta Tags */}
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="BrandBolt" />
      <meta property="og:image" content="https://brandbolt.ai/og-image.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content="https://brandbolt.ai/og-image.png" />
      <meta name="twitter:creator" content="@brandbolt" />
      <meta name="twitter:site" content="@brandbolt" />
    </Helmet>
  );
};

export default MetaTags;