import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, Copy, Check, Globe } from 'lucide-react';
import { GeneratedName } from '../types';
import { checkDomainsAvailability } from '../services/domainService';
import PremiumModal from './PremiumModal';
import NameCard from './results/NameCard';
import TldSelector from './results/TldSelector';
import api from '../services/api';
import { addNamesToHistory, filterRecentlyGeneratedNames, updateDailyUsage } from '../services/nameHistory';
import { useAuth } from '../contexts/AuthContext';

interface ResultsListProps {
  names: GeneratedName[];
  onGenerateNames: (names: GeneratedName[]) => void;
}

const ResultsList: React.FC<ResultsListProps> = ({ names, onGenerateNames }) => {
  const [domainStatuses, setDomainStatuses] = useState<Record<string, any>>({});
  const [isGeneratingMore, setIsGeneratingMore] = useState(false);
  const [selectedTlds, setSelectedTlds] = useState<string[]>(['com', 'net']);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [isAutoGenerating, setIsAutoGenerating] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  const checkDomains = async (namesToCheck: string[]) => {
    const domains = namesToCheck.flatMap(name => 
      selectedTlds.map(tld => `${formatDomainName(name)}.${tld}`)
    );
    
    setDomainStatuses(prev => {
      const updates: Record<string, any> = {};
      domains.forEach(domain => {
        updates[domain] = { 
          ...prev[domain], 
          isChecking: true,
          error: undefined,
          domain,
          available: false // Set initial domain availability to false
        };
      });
      return { ...prev, ...updates };
    });

    try {
      const results = await checkDomainsAvailability(domains);
      console.log('Domain check results:', results);
      setDomainStatuses(prev => {
        const updates = { ...prev };
        // Update each domain status
        Object.entries(results).forEach(([domain, result]) => {
          updates[domain] = {
            ...updates[domain],
            ...result,
            isChecking: false
          };
        });
        return updates;
      });

      if (showOnlyAvailable) {
        const availableCount = getAvailableNamesCount();
        if (availableCount < 5 && !isAutoGenerating) {
          await handleAutoGenerate();
        }
      }
    } catch (error) {
      console.error('Error checking domains:', error);
      setDomainStatuses(prev => {
        const updates: Record<string, any> = {};
        domains.forEach(domain => {
          updates[domain] = {
            isAvailable: false,
            isChecking: false,
            domain,
            error: 'Failed to check domain availability'
          };
        });
        return { ...prev, ...updates };
      });
    }
  };

  const formatDomainName = (name: string) => {
    return name.toLowerCase().replace(/[^a-z0-9-]/g, '');
  };

  const getAvailableNamesCount = () => {
    return names.filter(name => {
      const baseDomain = formatDomainName(name.name);
      return selectedTlds.some(tld => {
        const domain = `${baseDomain}.${tld}`;
        const status = domainStatuses[domain];
        return status && status.isAvailable;
      });
    }).length;
  };

  const handleAutoGenerate = async () => {
    if (isAutoGenerating) return;

    setIsAutoGenerating(true);
    try {
      const newNames = await generateNewNames();
      if (newNames && newNames.length > 0) {
        onGenerateNames([...names, ...newNames]);
      }
    } catch (error) {
      console.error('Error generating names:', error);
    } finally {
      setIsAutoGenerating(false);
    }
  };

  const generateNewNames = async (): Promise<GeneratedName[] | undefined> => {
    try {
      const lastBusinessDescription = localStorage.getItem('lastBusinessDescription');
      if (!lastBusinessDescription) {
        console.error('No previous business description found');
        return undefined;
      }

      // Update usage before making the request
      if (!currentUser && !updateDailyUsage(5, false)) {
        setError('Daily free tier limit reached. Sign up for more generations!');
        return undefined;
      }

      const response = await api.post('/generate-names', {
        businessDescription: lastBusinessDescription,
        minLength: parseInt(localStorage.getItem('lastMinLength') || '3'),
        maxLength: parseInt(localStorage.getItem('lastMaxLength') || '20'),
        keywords: localStorage.getItem('lastKeywords') || '',
        focusIndustries: JSON.parse(localStorage.getItem('lastFocusIndustries') || '[]'),
        avoidIndustries: JSON.parse(localStorage.getItem('lastAvoidIndustries') || '[]'),
        startingLetter: localStorage.getItem('lastStartingLetter') || ''
      });

      // Ensure we have an array of names
      const generatedNames = Array.isArray(response.data) ? response.data : [];
      
      // Filter out recently generated names
      const uniqueNames = filterRecentlyGeneratedNames(generatedNames);
      
      if (uniqueNames.length === 0) {
        console.log('No unique names generated');
        return undefined;
      }
      
      // Transform the names
      const transformedNames = uniqueNames.map(name => ({
        name: name.name || '',
        relevance: name.relevance || '',
        score: name.uniqueness_score || name.memorability_score || 0,
        domain: `${(name.name || '').toLowerCase().replace(/\s/g, '')}.com`,
        available: Math.random() < 0.7
      }));

      // Add new names to history
      addNamesToHistory(transformedNames);
      
      return transformedNames;
    } catch (error) {
      console.error('Error generating new names:', error);
      return undefined;
    }
  };

  const handleGenerateMore = async () => {
    if (isGeneratingMore) return;
    setIsGeneratingMore(true);
    
    // Scroll to the description box
    const descriptionElement = document.getElementById('businessDescription');
    if (descriptionElement) {
      descriptionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Find the closest form element and submit it
    const form = descriptionElement?.closest('form');
    if (form) {
      form.requestSubmit();
    }

    setIsGeneratingMore(false);
  };

  useEffect(() => {
    if (names.length > 0) {
      const uncheckedNames = names.map(name => name.name);
      if (uncheckedNames.length > 0) {
        checkDomains(uncheckedNames);
      }
    }
  }, [names, selectedTlds]);

  useEffect(() => {
    if (showOnlyAvailable) {
      const availableCount = getAvailableNamesCount();
      if (availableCount < 5 && !isAutoGenerating) {
        handleAutoGenerate();
      }
    }
  }, [showOnlyAvailable, domainStatuses]);

  if (names.length === 0) {
    return null;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="glass-effect p-6 sm:p-8 rounded-2xl mt-8"
      >
        <TldSelector
          selectedTlds={selectedTlds}
          setSelectedTlds={setSelectedTlds}
          showOnlyAvailable={showOnlyAvailable}
          setShowOnlyAvailable={setShowOnlyAvailable}
          onShowPremiumModal={() => setShowPremiumModal(true)}
        />

        <div className="space-y-4 sm:space-y-6">
          <AnimatePresence mode="wait">
            {names.map((name, index) => (
              <NameCard
                key={index}
                name={name}
                index={index}
                onRetry={(name) => checkDomains([name])}
                selectedTlds={selectedTlds}
                domainStatuses={domainStatuses}
                showOnlyAvailable={showOnlyAvailable}
                onShowPremiumModal={() => setShowPremiumModal(true)}
              />
            ))}
          </AnimatePresence>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-8 text-center"
        >
          <motion.button
            type="button"
            onClick={() => handleGenerateMore()}
            disabled={isGeneratingMore || isAutoGenerating}
            className="px-8 py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold flex items-center justify-center gap-2 mx-auto hover:opacity-90 transition-opacity"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isGeneratingMore || isAutoGenerating ? (
              <>
                <Sparkles className="animate-spin" size={20} />
                {isAutoGenerating ? 'Auto-generating...' : 'Generating...'}
              </>
            ) : (
              <>
                <Sparkles size={20} />
                Generate More Names
              </>
            )}
          </motion.button>
        </motion.div>
      </motion.div>

      <PremiumModal 
        isOpen={showPremiumModal} 
        onClose={() => setShowPremiumModal(false)} 
      />
    </>
  );
};

export default ResultsList;