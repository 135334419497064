import React, { useState, useEffect } from 'react';
import { GeneratedName } from '../types';
import { Sparkles, ChevronDown, ChevronUp, Wand2, Zap } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { updateUsage, getRemainingGenerations } from '../services/usageLimit';
import { useAuth } from '../contexts/AuthContext';
import { addNamesToHistory, filterRecentlyGeneratedNames } from '../services/nameHistory';
import env from '../config/env';
import api from '../services/api';
import PremiumModal from './PremiumModal';

interface NameGeneratorProps {
  onGenerateNames: (names: GeneratedName[]) => void;
}

const NameGenerator: React.FC<NameGeneratorProps> = ({ onGenerateNames }) => {
  const [businessDescription, setBusinessDescription] = useState('');
  const [minLength, setMinLength] = useState(3);
  const [maxLength, setMaxLength] = useState(20);
  const [keywords, setKeywords] = useState('');
  const [focusIndustries, setFocusIndustries] = useState<string[]>([]);
  const [avoidIndustries, setAvoidIndustries] = useState<string[]>([]);
  const [startingLetter, setStartingLetter] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    const storedBusinessDescription = localStorage.getItem('lastBusinessDescription');
    const storedMinLength = localStorage.getItem('lastMinLength');
    const storedMaxLength = localStorage.getItem('lastMaxLength');
    const storedKeywords = localStorage.getItem('lastKeywords');
    const storedFocusIndustries = localStorage.getItem('lastFocusIndustries');
    const storedAvoidIndustries = localStorage.getItem('lastAvoidIndustries');
    const storedStartingLetter = localStorage.getItem('lastStartingLetter');

    if (storedBusinessDescription) setBusinessDescription(storedBusinessDescription);
    if (storedMinLength) setMinLength(Number(storedMinLength));
    if (storedMaxLength) setMaxLength(Number(storedMaxLength));
    if (storedKeywords) setKeywords(storedKeywords);
    if (storedFocusIndustries) setFocusIndustries(JSON.parse(storedFocusIndustries));
    if (storedAvoidIndustries) setAvoidIndustries(JSON.parse(storedAvoidIndustries));
    if (storedStartingLetter) setStartingLetter(storedStartingLetter);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const isPremiumUser = !!currentUser;
    const remainingGenerations = getRemainingGenerations(isPremiumUser);
    
    if (!isPremiumUser && remainingGenerations < 5) {
      setShowPremiumModal(true);
      setIsLoading(false);
      return;
    }

    try {
      // Update usage before making the request
      if (!isPremiumUser && !updateUsage(5, isPremiumUser)) {
        setError('Daily free tier limit reached. Sign up for more generations!');
        setIsLoading(false);
        return;
      }

      // Store the current parameters in localStorage
      localStorage.setItem('lastBusinessDescription', businessDescription);
      localStorage.setItem('lastMinLength', minLength.toString());
      localStorage.setItem('lastMaxLength', maxLength.toString());
      localStorage.setItem('lastKeywords', keywords);
      localStorage.setItem('lastFocusIndustries', JSON.stringify(focusIndustries));
      localStorage.setItem('lastAvoidIndustries', JSON.stringify(avoidIndustries));
      localStorage.setItem('lastStartingLetter', startingLetter);

      const response = await api.post('/generate-names', {
        businessDescription,
        minLength,
        maxLength,
        keywords,
        focusIndustries,
        avoidIndustries,
        startingLetter
      });

      // Ensure we have an array of names
      const generatedNames = Array.isArray(response.data) ? response.data : [];
      
      // Filter out recently generated names
      const uniqueNames = filterRecentlyGeneratedNames(generatedNames);
      
      // Transform the names
      const transformedNames = uniqueNames.map(name => ({
        name: name.name || '',
        relevance: name.relevance || '',
        score: name.uniqueness_score || name.memorability_score || 0,
        domain: `${(name.name || '').toLowerCase().replace(/\s/g, '')}.com`,
        available: Math.random() < 0.7
      }));

      onGenerateNames(transformedNames);
    } catch (error: any) {
      console.error('Error generating names:', error);
      let errorMessage = 'An unexpected error occurred. Please try again.';
      
      if (error.response?.data?.error) {
        errorMessage = `Error: ${error.response.data.error}`;
      } else if (error.message) {
        errorMessage = `Error: ${error.message}`;
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const remainingGenerations = getRemainingGenerations(!!currentUser);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="glass-effect rounded-2xl p-8 sm:p-12 animate-pulse-glow relative overflow-hidden max-w-3xl mx-auto"
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5 bg-hero-pattern" />

      {/* Content */}
      <div className="relative z-10">
        <motion.div 
          className="flex flex-col items-center text-center gap-3 mb-12"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="p-3 bg-brand-primary/10 rounded-xl mb-4">
            <Wand2 className="w-10 h-10 text-brand-primary" />
          </div>
          <h2 className="text-4xl font-bold text-gradient">
            Generate Your Perfect Name
          </h2>
          <p className="text-gray-400 mt-2 max-w-xl">
            Describe your business and let our AI create unique, memorable names for your brand
          </p>
          {!currentUser && (
            <p className="text-sm text-violet-400 mt-2">
              {remainingGenerations} free generations remaining today
            </p>
          )}
        </motion.div>
        
        <form onSubmit={handleSubmit} className="space-y-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-2xl mx-auto"
          >
            <label htmlFor="businessDescription" className="block text-sm font-medium text-gray-300 mb-3 text-center">
              Describe Your Business
            </label>
            <textarea
              id="businessDescription"
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
              className="w-full min-h-[120px] bg-black/20 border border-gray-700 rounded-xl px-4 py-3 text-white placeholder-gray-400 focus:border-violet-500 focus:ring-2 focus:ring-violet-500/20 transition-all duration-200"
              placeholder="Tell us about your business idea, target audience, and unique value proposition..."
              required
            />
          </motion.div>

          <motion.div
            className="flex justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <motion.button
              type="button"
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="flex items-center text-brand-accent hover:text-brand-primary transition-colors gap-2 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              aria-label={showAdvanced ? "Hide advanced options" : "Show advanced options"}
              aria-expanded={showAdvanced}
            >
              <div className="p-1 bg-brand-primary/10 rounded-lg group-hover:bg-brand-primary/20 transition-colors">
                {showAdvanced ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </div>
              <span>Advanced Options</span>
            </motion.button>
          </motion.div>

          <AnimatePresence>
            {showAdvanced && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6 overflow-hidden max-w-2xl mx-auto"
              >
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="minLength" className="block text-sm font-medium text-gray-300 mb-2">
                      Minimum Length
                    </label>
                    <input
                      type="number"
                      id="minLength"
                      value={minLength}
                      onChange={(e) => setMinLength(Number(e.target.value))}
                      className="w-full bg-black/20 border border-gray-700 rounded-xl px-4 py-2 text-white focus:border-violet-500 focus:ring-2 focus:ring-violet-500/20 transition-all duration-200"
                      min={1}
                      max={20}
                    />
                  </div>
                  <div>
                    <label htmlFor="maxLength" className="block text-sm font-medium text-gray-300 mb-2">
                      Maximum Length
                    </label>
                    <input
                      type="number"
                      id="maxLength"
                      value={maxLength}
                      onChange={(e) => setMaxLength(Number(e.target.value))}
                      className="w-full bg-black/20 border border-gray-700 rounded-xl px-4 py-2 text-white focus:border-violet-500 focus:ring-2 focus:ring-violet-500/20 transition-all duration-200"
                      min={1}
                      max={20}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="keywords" className="block text-sm font-medium text-gray-300 mb-2">
                    Keywords
                  </label>
                  <input
                    type="text"
                    id="keywords"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    className="w-full bg-black/20 border border-gray-700 rounded-xl px-4 py-2 text-white focus:border-violet-500 focus:ring-2 focus:ring-violet-500/20 transition-all duration-200"
                    placeholder="Enter keywords separated by commas"
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div
            className="pt-4 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <motion.button
              type="submit"
              className="w-full py-4 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold flex items-center justify-center gap-3 disabled:opacity-50 hover:opacity-90 transition-opacity"
              disabled={isLoading}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              aria-label={isLoading ? "Generating names..." : "Generate business names"}
            >
              {isLoading ? (
                <>
                  <Sparkles className="animate-spin" size={20} />
                  Generating...
                </>
              ) : (
                <>
                  <Zap size={20} />
                  Generate Names
                </>
              )}
            </motion.button>
          </motion.div>
        </form>

        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl text-red-400 text-center max-w-2xl mx-auto"
          >
            {error}
          </motion.div>
        )}
        <div className="w-full max-w-3xl mx-auto px-4">
          <PremiumModal 
            isOpen={showPremiumModal} 
            onClose={() => setShowPremiumModal(false)} 
          />
        </div>
      </div>
    </motion.div>
  );
};

export default NameGenerator;