const env = {
  // API URLs
  SERVER_URL: import.meta.env.VITE_SERVER_URL || '/.netlify/functions',
  SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
  SUPABASE_ANON_KEY: import.meta.env.VITE_SUPABASE_ANON_KEY,
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  NAMECHEAP_API_KEY: import.meta.env.VITE_NAMECHEAP_API_KEY,
  NAMECHEAP_API_USER: import.meta.env.VITE_NAMECHEAP_API_USER,
  NAMECHEAP_USERNAME: import.meta.env.VITE_NAMECHEAP_USERNAME,
  NAMECHEAP_CLIENT_IP: import.meta.env.VITE_NAMECHEAP_CLIENT_IP,
  
  // Feature flags
  IS_PRODUCTION: import.meta.env.PROD,
  
  // API Configuration
  API_TIMEOUT: 30000,
  
  // Development fallbacks
  getDevelopmentFallbacks() {
    if (!this.IS_PRODUCTION) {
      return {
        SUPABASE_URL: 'https://example.supabase.co',
        SUPABASE_ANON_KEY: 'example-anon-key',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_example',
      };
    }
    return {};
  },
  
  get(key) {
    const value = this[key];
    const fallbacks = this.getDevelopmentFallbacks();
    
    if (!value && fallbacks[key]) {
      console.warn(`Using development fallback for ${key}`);
      return fallbacks[key];
    }
    
    return value;
  }
};

export default env;