import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Zap, Globe, Shield, Star, AlertCircle, Mail } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession } from '../services/stripe';
import { STRIPE_PRICES } from '../services/stripe/config';
import { Link, useSearchParams } from 'react-router-dom';
import MetaTags from '../components/SEO/MetaTags';
import JsonLd from '../components/SEO/JsonLd';
import PurchaseFlow from '../components/PurchaseFlow';

const PricingTier: React.FC<{ 
  name: string; 
  price: string; 
  features: string[]; 
  recommended?: boolean;
  icon: React.ReactNode;
  description: string;
  priceId: string;
  unavailable?: boolean;
}> = ({ name, price, features, recommended, icon, description, priceId, unavailable }) => {
  const { currentUser } = useAuth();
  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);

  const handleSubscribe = () => {
    if (unavailable) {
      alert('This plan is currently unavailable. Please choose another plan.');
      return;
    }
    setShowPurchaseFlow(true);
  };

  return (
    <>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`glass-effect p-8 rounded-2xl relative ${recommended ? 'border-2 border-violet-500' : 'border border-gray-800'} transition-all duration-300 hover:border-violet-500/50`}
        style={{
          willChange: 'transform',
          transform: 'translateZ(0)'
        }}
        whileHover={{
          y: -8,
          transition: { duration: 0.3, ease: 'easeOut' }
        }}
      >
        {recommended && (
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-violet-600 to-violet-400 text-white px-4 py-1 rounded-full text-sm font-semibold">
            Most Popular
          </div>
        )}
        {unavailable && (
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-300 px-4 py-1 rounded-full text-sm font-semibold flex items-center gap-1">
            <AlertCircle size={14} />
            Coming Soon
          </div>
        )}
        <div className="p-3 bg-violet-500/10 rounded-xl w-fit mb-4">
          {icon}
        </div>
        <h3 className="text-2xl font-bold mb-2 text-white">{name}</h3>
        <p className="text-gray-400 mb-4 text-sm">{description}</p>
        <div className="text-4xl font-bold mb-2 text-gradient">{price}</div>
        <p className="text-sm text-gray-400 mb-6">per month</p>
        <ul className="space-y-3 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <div className="p-1 bg-violet-500/10 rounded-lg mr-2">
                <Zap className="text-violet-400 w-4 h-4" />
              </div>
              <span className="text-gray-300">{feature}</span>
            </li>
          ))}
        </ul>
        <motion.button
          onClick={handleSubscribe}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={`w-full py-3 rounded-xl text-white font-semibold transition-all duration-200 ${
            unavailable 
              ? 'bg-gray-800 text-gray-400 cursor-not-allowed'
              : recommended 
                ? 'bg-gradient-to-r from-violet-600 to-violet-400 hover:opacity-90' 
                : 'bg-gray-800 hover:bg-gray-700'
          }`}
          disabled={unavailable}
        >
          {unavailable ? 'Coming Soon' : 'Get Started'}
        </motion.button>
      </motion.div>
      
      {showPurchaseFlow && (
        <PurchaseFlow 
          priceId={priceId}
          planName={name}
          onClose={() => setShowPurchaseFlow(false)}
        />
      )}
    </>
  );
};

const Pricing: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<{
    priceId: string;
    name: string;
  } | null>(null);
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    // Check if we're returning from email verification
    const verificationComplete = searchParams.get('verification_complete');
    const priceId = searchParams.get('price_id');
    const planName = searchParams.get('plan_name');
    
    if (verificationComplete === 'true' && priceId && planName) {
      setSelectedPlan({ priceId, name: planName });
    }
  }, [searchParams]);

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <MetaTags page="pricing" />
      <JsonLd page="pricing" url="https://brandbolt.ai/pricing" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            Choose Your Perfect Plan
          </h1>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Start generating unique brand names with our flexible pricing options. 
            Upgrade or downgrade anytime.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <PricingTier
            name="Free"
            price="$0"
            description="Perfect for individuals and small projects"
            icon={<Zap className="w-6 h-6 text-violet-400" />}
            priceId={STRIPE_PRICES.FREE}
            features={[
              "20 daily name generations",
              "Basic domain availability check",
              "Save up to 10 name ideas",
              "Ad-supported experience",
              "Community support"
            ]}
          />
          <PricingTier
            name="Pro"
            price="$25"
            description="Ideal for startups and growing businesses"
            icon={<Star className="w-6 h-6 text-violet-400" />}
            priceId={STRIPE_PRICES.PRO}
            features={[
              "1000 daily name generations",
              "8 TLD domain checks",
              "Unlimited name saves",
              "Ad-free experience",
              "Priority email support",
              "Advanced name analytics"
            ]}
            recommended
          />
          <PricingTier
            name="Enterprise"
            price="$199"
            description="For agencies and large organizations"
            icon={<Globe className="w-6 h-6 text-violet-400" />}
            priceId={STRIPE_PRICES.ENTERPRISE}
            features={[
              "Unlimited name generations",
              "Custom TLD checks",
              "Advanced analytics",
              "Dedicated support",
              "Custom integrations",
              "Team collaboration"
            ]}
            unavailable
          />
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="glass-effect p-8 rounded-2xl text-center mb-16"
        >
          <div className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4">
            <Globe className="w-8 h-8 text-violet-400" />
          </div>
          <h2 className="text-2xl font-bold mb-4 text-white">Need a Custom Solution?</h2>
          <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
            Looking for a white-label solution or custom features for your business? 
            We offer tailored enterprise packages to meet your specific needs.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
          >
            <Mail className="w-5 h-5" />
            Contact Sales
          </Link>
        </motion.div>

        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-2xl font-bold mb-6 text-white">Frequently Asked Questions</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="glass-effect p-6 rounded-xl text-left">
              <h3 className="text-lg font-semibold mb-2 text-white">Can I change plans later?</h3>
              <p className="text-gray-400">Yes, you can upgrade or downgrade your plan at any time. Changes take effect immediately.</p>
            </div>
            <div className="glass-effect p-6 rounded-xl text-left">
              <h3 className="text-lg font-semibold mb-2 text-white">What payment methods do you accept?</h3>
              <p className="text-gray-400">We accept all major credit cards, PayPal, and cryptocurrency payments.</p>
            </div>
          </div>
        </div>
      </div>
      {selectedPlan && (
        <PurchaseFlow
          priceId={selectedPlan.priceId}
          planName={selectedPlan.name}
          onClose={() => setSelectedPlan(null)}
        />
      )}
    </div>
  );
};

export default Pricing;