import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CookieProvider } from './contexts/CookieContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import Home from './pages/Home';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Features from './pages/Features';
import Contact from './pages/Contact';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookie from './pages/Cookie';
import GDPR from './pages/GDPR';
import AcceptableUse from './pages/AcceptableUse';
import FAQ from './pages/FAQ';
import Disclaimer from './pages/Disclaimer';
import Tools from './pages/Tools';
import BusinessNameGenerator from './pages/tools/BusinessNameGenerator';
import AgencyNameGenerator from './pages/tools/AgencyNameGenerator';
import SaasNameGenerator from './pages/tools/SaasNameGenerator';
import OnlineStoreNameGenerator from './pages/tools/OnlineStoreNameGenerator';
import FashionBrandNameGenerator from './pages/tools/FashionBrandNameGenerator';
import DomainNameGenerator from './pages/tools/DomainNameGenerator';
import StartupNameGenerator from './pages/tools/StartupNameGenerator';
import TestStripe from './components/TestStripe';
import ErrorBoundary from './components/ErrorBoundary';
import Blog from './pages/Blog';
import HowToChooseBusinessName from './pages/blog/HowToChooseBusinessName';
import BusinessNameLegalChecklist from './pages/blog/BusinessNameLegalChecklist';
import PsychologyBusinessNames from './pages/blog/PsychologyBusinessNames';
import DomainStrategies from './pages/blog/DomainStrategies';
import TestBusinessName from './pages/blog/TestBusinessName';
import BingTracking from './components/BingTracking';
import TwitterPixel from './components/TwitterPixel';

// Scroll to top component
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        {/* Base Meta Tags that should be present on all pages */}
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="msvalidate.01" content="A33B917D47688B87A82919E335458FB6" />
          
          {/* Security Headers */}
          <meta httpEquiv="Content-Security-Policy" content="default-src 'self' https: data:; 
                         script-src 'self' 'unsafe-inline' 'unsafe-eval' 'wasm-unsafe-eval' https://js.stripe.com https://www.googletagmanager.com https://www.google-analytics.com https://bat.bing.com https://www.clarity.ms https://static.ads-twitter.com;
                         style-src 'self' 'unsafe-inline' https:; 
                         img-src 'self' data: https:; 
                         font-src 'self' data: https:; 
                         connect-src 'self' https://*.supabase.co https://api.openai.com https://*.stripe.com https://www.google-analytics.com https://www.googletagmanager.com https://stats.g.doubleclick.net https://bat.bing.com https://*.clarity.ms https://analytics.twitter.com;
                         worker-src 'self' blob:;" />
          
          {/* Default title and meta description (will be overridden by specific pages) */}
          <title>BrandBolt | AI Business Name Generator | Instant Brand Creation</title>
          <meta name="description" content="Create your perfect business name in seconds using advanced AI. Get instant domain availability, trademark checks, and brand identity suggestions. Used by 10,000+ entrepreneurs." />
          
          {/* Technical Meta Tags */}
          <meta name="robots" content="index, follow" />
          <meta name="format-detection" content="telephone=no" />
          <meta name="theme-color" content="#000000" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="apple-mobile-web-app-title" content="BrandBolt" />
          
          {/* Default OpenGraph Tags */}
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="BrandBolt" />
          <meta property="og:image" content="https://brandbolt.ai/og-image.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          
          {/* Bing UET Tracking */}
          <script>{`
            (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"187174276", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
          `}</script>
          
          {/* Default Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@brandbolt" />
          <meta name="twitter:site" content="@brandbolt" />
          <meta name="twitter:image" content="https://brandbolt.ai/og-image.png" />
        </Helmet>

        <Router>
          <ThemeProvider>
            <AuthProvider>
              <CookieProvider>
                <ScrollToTop />
                <BingTracking />
                <TwitterPixel />
                <div className="min-h-screen bg-black flex flex-col">
                  <Header />
                  <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route path="/features" element={<Features />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/terms" element={<Terms />} />
                      <Route path="/cookie" element={<Cookie />} />
                      <Route path="/gdpr" element={<GDPR />} />
                      <Route path="/acceptable-use" element={<AcceptableUse />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/disclaimer" element={<Disclaimer />} />
                      <Route path="/blog" element={<Blog />} />
                      <Route path="/blog/how-to-choose-business-name" element={<HowToChooseBusinessName />} />
                      <Route path="/blog/business-name-legal-checklist" element={<BusinessNameLegalChecklist />} />
                      <Route path="/blog/psychology-business-names" element={<PsychologyBusinessNames />} />
                      <Route path="/blog/domain-strategies" element={<DomainStrategies />} />
                      <Route path="/blog/test-business-name" element={<TestBusinessName />} />
                      <Route path="/tools" element={<Tools />} />
                      <Route path="/tools/business-name-generator" element={<BusinessNameGenerator />} />
                      <Route path="/tools/agency-name-generator" element={<AgencyNameGenerator />} />
                      <Route path="/tools/saas-name-generator" element={<SaasNameGenerator />} />
                      <Route path="/tools/online-store-name-generator" element={<OnlineStoreNameGenerator />} />
                      <Route path="/tools/fashion-brand-name-generator" element={<FashionBrandNameGenerator />} />
                      <Route path="/tools/domain-name-generator" element={<DomainNameGenerator />} />
                      <Route path="/tools/startup-name-generator" element={<StartupNameGenerator />} />
                      <Route path="/test-stripe" element={<TestStripe />} />
                    </Routes>
                  </main>
                  <Footer />
                  <CookieConsent />
                </div>
              </CookieProvider>
            </AuthProvider>
          </ThemeProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;