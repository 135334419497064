import React from 'react';

interface StatCardProps {
  percentage: string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ percentage, description }) => {
  return (
    <div className="bg-gradient-to-br from-blue-600 to-purple-600 rounded-lg p-6 text-center">
      <div className="text-4xl font-bold text-white mb-2">{percentage}</div>
      <p className="text-gray-200">{description}</p>
    </div>
  );
};

export default StatCard;
