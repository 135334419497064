import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Clock, Tag, ArrowRight, BookOpen } from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';
import { supabase } from '../lib/supabase';

const Blog: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const blogPosts = [
    {
      slug: 'how-to-choose-business-name',
      title: 'How to Choose a Business Name: The Ultimate Guide 2025',
      description: 'Learn the exact process of finding a name that resonates with your audience and sets you up for long-term success.',
      readTime: '10 min read',
      category: 'Guide',
      datePublished: '2025-01-01',
      dateModified: '2025-01-01',
      featured: false
    },
    {
      slug: 'test-business-name',
      title: 'How to Test Your Business Name Before Launch: Complete Guide 2025',
      description: 'Learn how to effectively test your business name before launch using AI tools, market research, and proven techniques.',
      readTime: '15 min read',
      category: 'Testing',
      datePublished: '2025-01-01',
      dateModified: '2025-01-01',
      featured: true
    },
    {
      slug: 'psychology-business-names',
      title: 'The Psychology Behind Memorable Business Names: Science-Backed Guide 2025',
      description: 'Discover the psychological principles that make business names memorable and effective.',
      readTime: '12 min read',
      category: 'Psychology',
      datePublished: '2025-01-01',
      dateModified: '2025-01-01',
      featured: false
    },
    {
      slug: 'domain-strategies',
      title: 'Domain Name Strategies When Your First Choice is Taken: Complete Guide 2025',
      description: 'Expert strategies for finding the perfect domain name when your first choice is unavailable.',
      readTime: '10 min read',
      category: 'Domains',
      datePublished: '2025-01-01',
      dateModified: '2025-01-01',
      featured: false
    },
    {
      slug: 'business-name-legal-checklist',
      title: 'Business Name Legal Checklist: Complete Guide 2025',
      description: 'Essential legal steps to protect your business name and brand identity.',
      readTime: '13 min read',
      category: 'Legal',
      datePublished: '2025-01-01',
      dateModified: '2025-01-01',
      featured: false
    }
  ];

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    try {
      setIsSubmitting(true);
      
      // Insert new subscriber
      const { error } = await supabase
        .from('newsletter_subscribers')
        .insert([
          { 
            email,
            subscribed_at: new Date().toISOString(),
            source: 'blog'
          }
        ]);

      if (error) {
        if (error.code === '23505') { // Unique violation error code
          toast.error('This email is already subscribed!');
          return;
        }
        console.error('Error inserting subscriber:', error);
        throw error;
      }

      toast.success('Successfully subscribed to the newsletter!');
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('Failed to subscribe. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const featuredPost = blogPosts.find(post => post.featured);
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "BrandBolt Business Naming Blog",
    "description": "Expert guides and tools for choosing, testing, and protecting your business name. Features AI-powered insights and professional branding strategies.",
    "url": "https://brandbolt.ai/blog",
    "publisher": {
      "@type": "Organization",
      "name": "BrandBolt",
      "logo": {
        "@type": "ImageObject",
        "url": "https://brandbolt.ai/logo.png"
      }
    },
    "blogPost": blogPosts.map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "description": post.description,
      "datePublished": post.datePublished,
      "dateModified": post.dateModified,
      "author": {
        "@type": "Organization",
        "name": "BrandBolt"
      },
      "publisher": {
        "@type": "Organization",
        "name": "BrandBolt",
        "logo": {
          "@type": "ImageObject",
          "url": "https://brandbolt.ai/logo.png"
        }
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `https://brandbolt.ai/blog/${post.slug}`
      },
      "timeRequired": post.readTime
    }))
  };

  return (
    <>
      <Toaster position="top-center" />
      <Helmet>
        <title>Business Naming Blog: Expert Guides & Tools | BrandBolt</title>
        <meta 
          name="description" 
          content="Discover expert guides on business naming, from psychology to legal protection. Get AI-powered insights, testing tools, and professional strategies for your brand." 
        />
        <meta 
          name="keywords" 
          content="business name, brand name, company naming, business name generator, name testing, trademark protection" 
        />
        <link rel="canonical" href="https://brandbolt.ai/blog" />
        <meta 
          property="og:title" 
          content="Business Naming Blog: Expert Guides & Tools | BrandBolt" 
        />
        <meta 
          property="og:description" 
          content="Expert guides on business naming, from psychology to legal protection. Features AI-powered insights and professional strategies." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://brandbolt.ai/blog" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 leading-tight">
            Latest Insights & Strategies
          </h1>
          <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
            Expert guides on business naming, branding, and digital strategy. Stay ahead with our latest research and industry insights.
          </p>
        </div>

        {/* Featured Post */}
        {featuredPost && (
          <div className="mb-16">
            <Link 
              to={`/blog/${featuredPost.slug}`}
              className="block bg-gradient-to-br from-violet-500/10 to-blue-500/10 rounded-2xl p-px hover:from-violet-500/20 hover:to-blue-500/20 transition-all duration-300"
            >
              <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8">
                <div className="flex flex-wrap items-center gap-4 mb-6">
                  <span className="bg-violet-500/20 text-violet-300 text-sm font-medium px-4 py-1.5 rounded-full">
                    Featured
                  </span>
                  <div className="flex items-center text-gray-400 text-sm">
                    <Clock size={16} className="mr-2" />
                    <span>{featuredPost.readTime}</span>
                    <span className="mx-2">•</span>
                    <Tag size={16} className="mr-2" />
                    <span>{featuredPost.category}</span>
                  </div>
                </div>
                <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-white mb-4 group-hover:text-violet-300 transition-colors">
                  {featuredPost.title}
                </h2>
                <p className="text-gray-300 text-lg mb-6">
                  {featuredPost.description}
                </p>
                <div className="inline-flex items-center text-violet-400 font-medium hover:text-violet-300 transition-colors">
                  Read Article <ArrowRight size={20} className="ml-2 group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            </Link>
          </div>
        )}

        {/* All Posts Grid */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {blogPosts
            .filter(post => !post.featured)
            .map((post) => (
              <Link 
                key={post.slug}
                to={`/blog/${post.slug}`}
                className="group block bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl p-6 hover:from-gray-800 hover:to-gray-700 transition-all duration-300 h-full"
              >
                <div className="flex items-center text-gray-400 text-sm mb-4">
                  <Clock size={16} className="mr-2" />
                  <span>{post.readTime}</span>
                  <span className="mx-2">•</span>
                  <Tag size={16} className="mr-2" />
                  <span>{post.category}</span>
                </div>
                <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-white mb-4 group-hover:text-violet-300 transition-colors">
                  {post.title}
                </h2>
                <p className="text-gray-300 mb-4 flex-grow">
                  {post.description}
                </p>
                <div className="flex items-center text-violet-400 font-medium group-hover:text-violet-300">
                  Read Article <ArrowRight size={20} className="ml-2 group-hover:translate-x-1 transition-transform" />
                </div>
              </Link>
            ))}
        </div>

        {/* Newsletter Section */}
        <div className="mt-16 bg-gradient-to-br from-violet-500/10 to-blue-500/10 rounded-2xl p-px">
          <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-center">
            <BookOpen size={32} className="mx-auto mb-4 text-violet-400" />
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
              Stay Updated with Latest Insights
            </h2>
            <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
              Join our newsletter to receive expert tips, industry insights, and exclusive guides on business naming and branding.
            </p>
            <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-grow px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-violet-500 text-white"
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-6 py-2 bg-violet-500 hover:bg-violet-600 text-white font-medium rounded-lg transition-colors ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
