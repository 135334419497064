import { GeneratedName } from '../types';

interface NameHistoryEntry {
  name: string;
  timestamp: number;
}

const HISTORY_KEY = 'brandbolt_name_history';
const USAGE_KEY = 'brandbolt_daily_usage';
const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const getNameHistory = (): NameHistoryEntry[] => {
  try {
    const history = localStorage.getItem(HISTORY_KEY);
    return history ? JSON.parse(history) : [];
  } catch {
    return [];
  }
};

export const getDailyUsage = (): number => {
  try {
    const usage = localStorage.getItem(USAGE_KEY);
    if (!usage) return 0;
    
    const { count, timestamp } = JSON.parse(usage);
    const currentTime = Date.now();
    
    // Reset if it's been more than 24 hours
    if (currentTime - timestamp > ONE_DAY_MS) {
      localStorage.removeItem(USAGE_KEY);
      return 0;
    }
    
    return count;
  } catch {
    return 0;
  }
};

export const updateDailyUsage = (amount: number, isPremium: boolean): boolean => {
  try {
    const currentUsage = getDailyUsage();
    const maxDaily = isPremium ? 1000 : 20; // Premium users get 1000 generations per day, free users get 20
    
    if (currentUsage + amount > maxDaily) {
      return false;
    }
    
    localStorage.setItem(USAGE_KEY, JSON.stringify({
      count: currentUsage + amount,
      timestamp: Date.now()
    }));
    
    return true;
  } catch {
    return false;
  }
};

export const addNamesToHistory = (names: GeneratedName[]) => {
  try {
    const currentTime = Date.now();
    const newEntries = names.map(name => ({
      name: name.name.toLowerCase(), // Store lowercase for case-insensitive comparison
      timestamp: currentTime
    }));
    
    const history = getNameHistory();
    
    // Remove entries older than 24 hours
    const updatedHistory = [
      ...history.filter(entry => currentTime - entry.timestamp < ONE_DAY_MS),
      ...newEntries
    ];
    
    localStorage.setItem(HISTORY_KEY, JSON.stringify(updatedHistory));
  } catch {
    // Fail silently if localStorage is not available
    console.warn('Failed to save name history to localStorage');
  }
};

export const filterRecentlyGeneratedNames = (names: GeneratedName[]): GeneratedName[] => {
  try {
    const history = getNameHistory();
    const currentTime = Date.now();
    
    // Filter out names that are in history and less than 24 hours old
    return names.filter(name => {
      const historyEntry = history.find(
        entry => entry.name === name.name.toLowerCase() &&
        currentTime - entry.timestamp < ONE_DAY_MS
      );
      return !historyEntry;
    });
  } catch {
    // If there's any error, return original names
    return names;
  }
};
