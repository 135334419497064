import React from 'react';
import { Helmet } from 'react-helmet-async';

interface JsonLdProps {
  page: string;
  url: string;
}

const JsonLd: React.FC<JsonLdProps> = ({ page, url }) => {
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "BrandBolt",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "url": "https://brandbolt.ai",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "Create your perfect business name in seconds using advanced AI. Get instant domain availability, trademark checks, and brand identity suggestions. Used by 10,000+ entrepreneurs.",
    "featureList": [
      "AI-powered name generation",
      "Domain availability check",
      "Trademark screening",
      "Brand identity suggestions"
    ],
    "creator": {
      "@type": "Organization",
      "name": "BrandBolt",
      "url": "https://brandbolt.ai",
      "logo": "https://brandbolt.ai/og-image.png"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1000",
      "bestRating": "5",
      "worstRating": "1"
    }
  };

  const pricingSchema = {
    "@context": "https://schema.org",
    "@type": "PriceSpecification",
    "price": [
      {
        "@type": "PriceSpecification",
        "name": "Free Plan",
        "price": "0",
        "priceCurrency": "USD",
        "description": "Basic name generation and domain checks"
      },
      {
        "@type": "PriceSpecification",
        "name": "Pro Plan",
        "price": "25",
        "priceCurrency": "USD",
        "description": "Advanced features with unlimited generations"
      }
    ]
  };

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://brandbolt.ai"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": page.charAt(0).toUpperCase() + page.slice(1),
        "item": url
      }
    ]
  };

  const pageKey = page.toLowerCase();
  
  let schema = baseSchema;

  if (pageKey === 'about') {
    schema = {
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "name": "About BrandBolt",
      "description": "Learn about BrandBolt's mission to transform brand naming with advanced AI technology and real-time domain checking.",
      "url": url,
      "mainEntity": {
        "@type": "Organization",
        "name": "BrandBolt",
        "url": "https://brandbolt.ai",
        "logo": "https://brandbolt.ai/og-image.png",
        "description": "BrandBolt is an AI-powered business name generator that helps entrepreneurs and businesses find the perfect brand name with instant domain availability checks."
      }
    };
  } else if (pageKey === 'pricing') {
    schema = [baseSchema, pricingSchema, breadcrumbSchema];
  } else if (pageKey === 'home') {
    schema = [baseSchema];
  } else {
    schema = [baseSchema, breadcrumbSchema];
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export default JsonLd;
