import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Zap, BookOpen } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { toast, Toaster } from 'react-hot-toast';
import { supabase } from '../../lib/supabase';

interface TableOfContentsItem {
  id: string;
  title: string;
}

interface BlogPostProps {
  title: string;
  publishDate: string;
  readTime: string;
  author: string;
  description: string;
  tableOfContents: TableOfContentsItem[];
  children: React.ReactNode;
  imageUrl?: string;
}

const BlogPost: React.FC<BlogPostProps> = ({
  title,
  publishDate,
  readTime,
  author,
  description,
  tableOfContents,
  children,
  imageUrl = 'https://brandbolt.ai/og-image.jpg' // default OG image
}) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return;

    try {
      setIsSubmitting(true);
      
      // Insert new subscriber
      const { error } = await supabase
        .from('newsletter_subscribers')
        .insert([
          { 
            email,
            subscribed_at: new Date().toISOString(),
            source: 'blog-article'
          }
        ]);

      if (error) {
        if (error.code === '23505') { // Unique violation error code
          toast.error('This email is already subscribed!');
          return;
        }
        console.error('Error inserting subscriber:', error);
        throw error;
      }

      toast.success('Successfully subscribed to the newsletter!');
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('Failed to subscribe. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Create the schema markup
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: title,
    description: description,
    image: imageUrl,
    datePublished: publishDate,
    dateModified: publishDate,
    author: {
      '@type': 'Organization',
      name: 'BrandBolt Team',
      url: 'https://brandbolt.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://brandbolt.ai/logo.png'
      }
    },
    publisher: {
      '@type': 'Organization',
      name: 'BrandBolt',
      url: 'https://brandbolt.ai',
      logo: {
        '@type': 'ImageObject',
        url: 'https://brandbolt.ai/logo.png'
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://brandbolt.ai/blog/${title.toLowerCase().replace(/\s+/g, '-')}`
    },
    timeRequired: readTime
  };

  return (
    <article className="max-w-4xl mx-auto">
      <Toaster position="top-center" />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      {/* Header */}
      <header className="mb-12">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4 leading-tight">{title}</h1>
        <div className="flex items-center text-gray-400 space-x-4 mb-8">
          <span>{publishDate}</span>
          <span>•</span>
          <span>{readTime}</span>
          <span>•</span>
          <span className="flex items-center gap-2">
            By {author}
            <Zap className="w-4 h-4 text-violet-400 transform transition-transform group-hover:rotate-12" />
          </span>
        </div>
      </header>

      {/* Table of Contents */}
      <div className="bg-gray-900 rounded-lg p-6 mb-12">
        <h2 className="text-xl md:text-2xl font-bold text-white mb-4">Table of Contents</h2>
        <nav>
          <ul className="space-y-2">
            {tableOfContents.map((item) => (
              <li key={item.id}>
                <a
                  href={`#${item.id}`}
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {/* Content */}
      <div className="prose prose-invert prose-sm md:prose-base lg:prose-lg max-w-none">
        {children}
      </div>

      {/* Newsletter Section */}
      <div className="mt-16 bg-gradient-to-br from-violet-500/10 to-blue-500/10 rounded-2xl p-px">
        <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-center">
          <BookOpen size={32} className="mx-auto mb-4 text-violet-400" />
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
            Want More Business Naming Tips?
          </h2>
          <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
            Subscribe to our newsletter for expert tips, industry insights, and exclusive guides on business naming and branding.
          </p>
          <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-grow px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:border-violet-500 text-white"
              required
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-6 py-2 bg-violet-500 hover:bg-violet-600 text-white font-medium rounded-lg transition-colors ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isSubmitting ? 'Subscribing...' : 'Subscribe'}
            </button>
          </form>
        </div>
      </div>
    </article>
  );
};

export default BlogPost;
