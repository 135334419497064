import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Brain, Globe2, BarChart3, Target } from 'lucide-react';
import { motion } from 'framer-motion';
import MetaTags from '../components/SEO/MetaTags';
import JsonLd from '../components/SEO/JsonLd';

const About: React.FC = () => {
  return (
    <>
      <MetaTags 
        page="about" 
        title="About BrandBolt | AI Business Name Generator"
        description="Learn about BrandBolt's mission to transform brand naming with advanced AI technology and real-time domain checking. Discover how we help entrepreneurs find the perfect business name."
      />
      <JsonLd page="about" url="https://brandbolt.ai/about" />
      
      {/* Hero Section */}
      <section className="text-center py-16 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-violet-600/10 to-transparent" />
        <div className="max-w-4xl mx-auto px-4 relative z-10">
          <motion.h1 
            className="text-4xl md:text-6xl font-extrabold mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="text-gradient">About BrandBolt</span>
          </motion.h1>
          <motion.p 
            className="text-xl md:text-2xl text-gray-300 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.5 }}
          >
            Your Brand's Perfect Name Starts Here
          </motion.p>
        </div>
      </section>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-24">
        {/* Mission Section */}
        <section className="relative">
          <motion.div 
            className="bg-gradient-to-r from-violet-900/20 to-indigo-900/20 rounded-2xl p-8 backdrop-blur-sm"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <p className="text-lg text-gray-300 leading-relaxed">
              In today's crowded digital landscape, finding the perfect brand name can feel like searching for a needle in a haystack. That's why we built BrandBolt - to transform the naming process from weeks of frustration into minutes of inspiration.
            </p>
          </motion.div>
          
          <motion.div 
            className="mt-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-gradient mb-6">Our Mission</h2>
            <p className="text-lg text-gray-300">
              We're on a mission to help entrepreneurs and businesses find names that resonate, stand out, and succeed. By combining advanced AI technology with instant domain availability checking, we've created a tool that makes brand naming both powerful and effortless.
            </p>
          </motion.div>
        </section>

        {/* Features Grid */}
        <section>
          <motion.h2 
            className="text-3xl font-bold text-gradient mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            The BrandBolt Difference
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div 
              className="bg-gradient-to-r from-violet-900/10 to-indigo-900/10 p-8 rounded-2xl backdrop-blur-sm border border-violet-900/20"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Brain className="w-8 h-8 text-violet-400 mb-4" />
              <h3 className="text-xl font-semibold mb-3 text-white">Advanced AI Technology</h3>
              <p className="text-gray-300">
                Our AI doesn't just generate random names - it understands context, industry trends, and brand personality to create truly meaningful suggestions.
              </p>
            </motion.div>

            <motion.div 
              className="bg-gradient-to-r from-violet-900/10 to-indigo-900/10 p-8 rounded-2xl backdrop-blur-sm border border-violet-900/20"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Globe2 className="w-8 h-8 text-violet-400 mb-4" />
              <h3 className="text-xl font-semibold mb-3 text-white">Real-Time Domain Checking</h3>
              <p className="text-gray-300">
                No more falling in love with unavailable names. Every suggestion comes with instant domain availability verification.
              </p>
            </motion.div>

            <motion.div 
              className="bg-gradient-to-r from-violet-900/10 to-indigo-900/10 p-8 rounded-2xl backdrop-blur-sm border border-violet-900/20"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <BarChart3 className="w-8 h-8 text-violet-400 mb-4" />
              <h3 className="text-xl font-semibold mb-3 text-white">Smart Analytics</h3>
              <p className="text-gray-300">
                Make informed decisions with our analytics tools that evaluate name memorability, market trends, and brand potential.
              </p>
            </motion.div>

            <motion.div 
              className="bg-gradient-to-r from-violet-900/10 to-indigo-900/10 p-8 rounded-2xl backdrop-blur-sm border border-violet-900/20"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <Target className="w-8 h-8 text-violet-400 mb-4" />
              <h3 className="text-xl font-semibold mb-3 text-white">Industry-Focused</h3>
              <p className="text-gray-300">
                Whether you're a tech startup or a local business, our AI adapts to your specific industry and target audience.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Story Section */}
        <motion.section 
          className="relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gradient mb-6">Built for Entrepreneurs, by Entrepreneurs</h2>
            <p className="text-lg text-gray-300 mb-12">
              We created BrandBolt because we faced the same challenge ourselves - spending countless hours brainstorming names only to find the domains were taken. We knew there had to be a better way.
            </p>
          </div>
        </motion.section>

        {/* CTA Section */}
        <motion.section 
          className="relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <div className="bg-gradient-to-r from-violet-900/20 to-indigo-900/20 rounded-2xl p-12 text-center backdrop-blur-sm">
            <h2 className="text-3xl font-bold text-gradient mb-6">Start Your Naming Journey</h2>
            <p className="text-lg text-gray-300 mb-8">
              Whether you're launching a startup, rebranding your business, or helping clients find their perfect name, BrandBolt is here to help you make your mark.
            </p>
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400 }}
            >
              <Link 
                to="/" 
                className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium rounded-lg hover:from-violet-700 hover:to-indigo-700 transition-all duration-200"
              >
                Try BrandBolt Today
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </motion.div>
          </div>
        </motion.section>
      </div>
    </>
  );
};

export default About;
