import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    uetq: any[];
  }
}

const BingTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize Bing UET
    (function(w,d,t,r,u){
      var f,n,i;
      w[u]=w[u]||[],f=function(){
        var o={ti:"187174276", enableAutoSpaTracking: true};
        o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
      },
      n=d.createElement(t),n.src=r,n.async=1,
      n.onload=n.onreadystatechange=function(){
        var s=this.readyState;
        s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
      },
      i=d.getElementsByTagName(t)[0],
      i.parentNode.insertBefore(n,i)
    })(window,document,"script","https://bat.bing.com/bat.js","uetq");
  }, []);

  // Track page views
  useEffect(() => {
    if (window.uetq) {
      window.uetq.push('pageLoad');
    }
  }, [location]);

  return null;
};

export default BingTracking;
