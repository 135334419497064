import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Check, Mail, CreditCard, Loader } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession } from '../services/stripe';
import toast from 'react-hot-toast';
import { useSearchParams, useNavigate } from 'react-router-dom';

interface PurchaseFlowProps {
  priceId: string;
  planName: string;
  onClose: () => void;
}

const PurchaseFlow: React.FC<PurchaseFlowProps> = ({ priceId, planName, onClose }) => {
  const { currentUser, signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we're returning from email verification
    const verificationComplete = searchParams.get('verification_complete');
    const returnPriceId = searchParams.get('price_id');
    const returnPlanName = searchParams.get('plan_name');
    
    if (verificationComplete === 'true' && returnPriceId && returnPlanName) {
      if (currentUser?.email_confirmed_at) {
        setStep(3);
        // Clean up the URL
        navigate('/pricing', { replace: true });
      }
    }
  }, [currentUser, searchParams, navigate]);

  useEffect(() => {
    if (currentUser?.email_confirmed_at) {
      setStep(3);
    }
  }, [currentUser]);

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const redirectUrl = new URL(window.location.href);
      redirectUrl.searchParams.set('verification_complete', 'true');
      redirectUrl.searchParams.set('price_id', priceId);
      redirectUrl.searchParams.set('plan_name', planName);
      
      await signUp(email, password, {
        redirectTo: redirectUrl.toString()
      });
      setVerificationSent(true);
      toast.success('Verification email sent! Please check your inbox.');
      setStep(2);
    } catch (error) {
      toast.error('Failed to create account. Please try again.');
    }
    setIsLoading(false);
  };

  const handlePurchase = async () => {
    if (!currentUser) return;
    setIsLoading(true);
    try {
      await createCheckoutSession(priceId, currentUser.id);
    } catch (error) {
      toast.error('Failed to start checkout. Please try again.');
    }
    setIsLoading(false);
  };

  const steps = [
    { number: 1, title: 'Create Account', icon: <Mail className="w-6 h-6" /> },
    { number: 2, title: 'Verify Email', icon: <Check className="w-6 h-6" /> },
    { number: 3, title: 'Complete Purchase', icon: <CreditCard className="w-6 h-6" /> },
  ];

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-br from-gray-900 to-gray-800 p-8 rounded-2xl max-w-md w-full mx-4 relative"
      >
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          ×
        </button>

        {/* Progress Bar */}
        <div className="flex justify-between mb-8">
          {steps.map((s, i) => (
            <div key={s.number} className="flex flex-col items-center">
              <div className={`
                w-10 h-10 rounded-full flex items-center justify-center mb-2
                ${step >= s.number ? 'bg-violet-600' : 'bg-gray-700'}
                ${step === s.number ? 'ring-2 ring-violet-400 ring-offset-2 ring-offset-gray-900' : ''}
              `}>
                {s.icon}
              </div>
              <div className="text-sm text-gray-400">{s.title}</div>
              {i < steps.length - 1 && (
                <div className={`h-0.5 w-24 mt-5 ${step > s.number ? 'bg-violet-600' : 'bg-gray-700'}`} />
              )}
            </div>
          ))}
        </div>

        <div className="space-y-6">
          {step === 1 && (
            <form onSubmit={handleSignUp} className="space-y-4">
              <h2 className="text-2xl font-bold text-white">Create Your Account</h2>
              <p className="text-gray-400">Start your journey with {planName}</p>
              
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-violet-500"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-2 px-4 bg-violet-600 hover:bg-violet-700 text-white rounded-lg font-medium disabled:opacity-50"
              >
                {isLoading ? <Loader className="w-5 h-5 animate-spin mx-auto" /> : 'Continue'}
              </button>
            </form>
          )}

          {step === 2 && (
            <div className="text-center">
              <Mail className="w-16 h-16 mx-auto mb-4 text-violet-500" />
              <h2 className="text-2xl font-bold text-white mb-2">Check Your Email</h2>
              <p className="text-gray-400 mb-6">
                We've sent a verification link to {email}.<br />
                Click the link to verify your email and continue.
              </p>
              <div className="text-sm text-gray-500">
                Didn't receive the email? Check your spam folder or{' '}
                <button 
                  onClick={handleSignUp}
                  className="text-violet-500 hover:text-violet-400"
                >
                  resend verification
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="text-center">
              <h2 className="text-2xl font-bold text-white mb-2">Complete Your Purchase</h2>
              <p className="text-gray-400 mb-6">
                You're almost there! Click below to proceed to secure checkout.
              </p>
              <button
                onClick={handlePurchase}
                disabled={isLoading}
                className="w-full py-2 px-4 bg-violet-600 hover:bg-violet-700 text-white rounded-lg font-medium disabled:opacity-50"
              >
                {isLoading ? <Loader className="w-5 h-5 animate-spin mx-auto" /> : 'Proceed to Checkout'}
              </button>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default PurchaseFlow;
