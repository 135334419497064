import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Check, X, Loader2, AlertCircle, ExternalLink, DollarSign } from 'lucide-react';

interface DomainListProps {
  baseDomain: string;
  selectedTlds: string[];
  domainStatuses: Record<string, any>;
  showOnlyAvailable: boolean;
}

const formatPrice = (price: string | number) => {
  const numPrice = typeof price === 'string' ? parseFloat(price) : price;
  return numPrice.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const DomainList: React.FC<DomainListProps> = ({
  baseDomain,
  selectedTlds,
  domainStatuses,
  showOnlyAvailable,
}) => {
  const shouldShowDomain = (domain: string) => {
    const status = domainStatuses[domain];
    return !showOnlyAvailable || (status && status.isAvailable);
  };

  return (
    <div className="mt-4 space-y-2">
      {selectedTlds.map(tld => {
        const domain = `${baseDomain}.${tld}`;
        const status = domainStatuses[domain];

        if (!shouldShowDomain(domain)) {
          return null;
        }

        return (
          <div key={domain} className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {status?.isChecking ? (
                <>
                  <Loader2 size={16} className="animate-spin text-violet-400" />
                  <span className="text-sm text-gray-300">
                    Checking {domain}...
                  </span>
                </>
              ) : status?.error ? (
                <>
                  <AlertCircle size={16} className="text-red-400" />
                  <span className="text-sm text-red-400">{status.error}</span>
                </>
              ) : (
                <>
                  <Globe size={16} className="text-gray-300" />
                  <span className="text-sm font-medium text-gray-200">{domain}</span>
                  {status?.isAvailable ? (
                    <Check size={16} className="text-green-400" />
                  ) : (
                    <X size={16} className="text-red-400" />
                  )}
                  {status?.isPremium && (
                    <div className="ml-2 flex items-center text-yellow-400">
                      <DollarSign size={14} className="mr-1" />
                      <span className="text-sm">Premium</span>
                    </div>
                  )}
                </>
              )}
            </div>
            {status?.isAvailable ? (
              <motion.a
                href={`https://www.namecheap.com/domains/registration/results/?domain=${domain}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-3 py-1 rounded-lg text-sm bg-violet-500/10 text-violet-400 hover:bg-violet-500/20 transition-colors"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <ExternalLink size={14} />
                {status?.isPremium ? `Register (${formatPrice(status.premiumPrice)})` : 'Register Domain'}
              </motion.a>
            ) : !status?.isChecking && !status?.error && (
              <motion.a
                href={`https://www.namecheap.com/domains/registration/results/?domain=${domain}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-3 py-1 rounded-lg text-sm bg-gray-500/10 text-gray-400 hover:bg-gray-500/20 transition-colors"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <ExternalLink size={14} />
                {status?.isPremium ? `Premium (${formatPrice(status.premiumPrice)})` : 'Check Price'}
              </motion.a>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DomainList;