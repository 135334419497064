import React from 'react';
import { motion } from 'framer-motion';
import { Building, Sparkles, Target, CheckCircle, Globe, Zap, HelpCircle } from 'lucide-react';
import NameGenerator from '../../components/NameGenerator';
import ResultsList from '../../components/ResultsList';
import { GeneratedName } from '../../types';
import JsonLd from '../../components/SEO/JsonLd';
import MetaTags from '../../components/SEO/MetaTags';

const BusinessNameGenerator: React.FC = () => {
  const [generatedNames, setGeneratedNames] = React.useState<GeneratedName[]>([]);

  const handleGenerateNames = (names: GeneratedName[]) => {
    setGeneratedNames(names);
  };

  const features = [
    {
      icon: <Sparkles className="w-6 h-6" />,
      title: "AI-Powered Generation",
      description: "Advanced algorithms create unique business names based on your industry and preferences"
    },
    {
      icon: <Target className="w-6 h-6" />,
      title: "Industry-Specific",
      description: "Names tailored to your business sector and target audience"
    },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      title: "Instant Availability Check",
      description: "Real-time domain and trademark screening"
    }
  ];

  const examples = [
    {
      industry: "Technology",
      names: ["TechFlow", "ByteCore", "InnovateHub"],
      description: "Modern names for tech companies"
    },
    {
      industry: "Retail",
      names: ["ShopSpot", "RetailPro", "StoreSync"],
      description: "Catchy names for retail businesses"
    },
    {
      industry: "Services",
      names: ["ServeWell", "ProService", "ExpertCare"],
      description: "Professional service business names"
    }
  ];

  const tips = [
    "Keep it memorable and unique",
    "Consider your target market",
    "Check domain availability",
    "Make it easy to pronounce",
    "Think about branding potential",
    "Verify trademark availability"
  ];

  const faqs = [
    {
      question: "What makes a good business name?",
      answer: "A good business name should be memorable, reflect your brand values, be easy to pronounce and spell, have available domains, and be legally available for use."
    },
    {
      question: "Should I use a descriptive or creative name?",
      answer: "Both approaches can work. Descriptive names clearly communicate what you do, while creative names can be more memorable and versatile. Consider your industry and target audience when deciding."
    },
    {
      question: "How important is domain availability?",
      answer: "Very important. While alternative domain extensions exist, having a matching .com domain adds credibility and makes it easier for customers to find you online."
    }
  ];

  return (
    <>
      <MetaTags page="businessnamegenerator" />
      <JsonLd page="businessnamegenerator" url="https://brandbolt.ai/tools/business-name-generator" />
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="inline-block p-3 bg-violet-500/10 rounded-xl mb-4"
          >
            <Building className="w-12 h-12 text-violet-400" />
          </motion.div>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-gradient">
            Business Name Generator
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Create a powerful, memorable name for your business that resonates with your audience
          </p>
        </motion.div>

        {/* Generator Tool */}
        <NameGenerator onGenerateNames={handleGenerateNames} />
        <ResultsList names={generatedNames} onGenerateNames={handleGenerateNames} />

        {/* How It Works */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Target className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">1. Describe Your Business</h3>
              <p className="text-gray-300">Tell us about your business idea and industry</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <Sparkles className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">2. Generate Names</h3>
              <p className="text-gray-300">Our AI creates unique, industry-specific names</p>
            </motion.div>
            <motion.div 
              className="glass-effect p-6 rounded-xl text-center"
              whileHover={{ scale: 1.02 }}
            >
              <div className="p-3 bg-violet-500/10 rounded-xl inline-block mb-4">
                <CheckCircle className="w-8 h-8 text-violet-400" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-white">3. Pick Your Favorite</h3>
              <p className="text-gray-300">Choose and save your perfect business name</p>
            </motion.div>
          </div>
        </section>

        {/* Features */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl text-center"
                whileHover={{ scale: 1.02 }}
              >
                <div className="text-violet-400 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-white">{feature.title}</h3>
                <p className="mt-2 text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Examples */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Example Names</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {examples.map((example, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl"
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{example.industry}</h3>
                <ul className="space-y-2">
                  {example.names.map((name, nameIndex) => (
                    <li key={nameIndex} className="text-gray-300">{name}</li>
                  ))}
                </ul>
                <p className="mt-4 text-gray-400">{example.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Tips */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Naming Tips</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {tips.map((tip, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-4 rounded-xl flex items-center"
              >
                <div className="text-violet-400 mr-4">
                  <CheckCircle className="w-6 h-6" />
                </div>
                <p className="text-gray-300">{tip}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* FAQs */}
        <section className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-gradient">Frequently Asked Questions</h2>
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="glass-effect p-6 rounded-xl"
              >
                <h3 className="text-xl font-semibold mb-4 text-white">{faq.question}</h3>
                <p className="text-gray-300">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default BusinessNameGenerator;